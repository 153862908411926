import React, { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../Shared/Loading/Loading';
import AddCashModal from './AddCashModal';
import toast from 'react-hot-toast';
import ReactToPrint from 'react-to-print';
import PrintOrder from './PrintOrder';

const ViewOrder = () => {

    let { orderId } = useParams();
    const navigate = useNavigate();
    const componentRef = useRef();

    const [addCashshow, setAddCashShow] = useState(false);
    const handleAddCashClose = () => setAddCashShow(false);
    const handleAddCashShow = () => setAddCashShow(true);
    const [loading, setLoading] = useState(false);

    // getting specific category by id
    const { data: order, isLoading, refetch } = useQuery(['singleOrder', orderId], () =>
        fetch(`${process.env.REACT_APP_SERVER_URL}order/${orderId}`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
            .then(res => {
                if (res.status === 401 || res.status === 401) {
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('userId');
                    navigate('/login');
                    toast.error('Forbidden/Unauthorized access!');
                }
                return res.json();
            }))


    // add cash to order
    const cashModifyData = (cashdata, addCashBoolean) => {
        fetch(`${process.env.REACT_APP_SERVER_URL}order/add-cash/${order?.order._id}`, {
            method: 'PATCH',
            headers: {
                'content-Type': 'application/json',
                authorization: `Bearer ${localStorage.getItem('accessToken')}`
            },
            body: JSON.stringify(cashdata)
        })
            .then(res => {
                if (res.status === 401 || res.status === 403) {
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('userId');
                    navigate('/login');
                    toast.error('Forbidden/Unauthorized access!');
                }
                return res.json();
            })
            .then(data => {
                setLoading(false);
                if (data.message && data.success === true) {
                    refetch();
                    addCashBoolean && setAddCashShow(false);
                    toast.success(data.message);
                }
                if (data.message && data.success === false) {
                    toast.error(data.message);
                }
            })
    }

    // due paid
    const handleDuePaid = (e) => {
        e.preventDefault();
        setLoading(true);

        const today = new Date();
        const newCash = order?.order.cash + order?.order.due;
        const newDue = 0;

        const cashdata = { newCash: newCash, newPaidCash: order?.order.due, newDue: newDue, userId: order?.order.userId?._id, createdAt: today.toString() };

        cashModifyData(cashdata, false);
    }

    const pageStyle = `
        @page {
            size: A4;
            margin: 5mm 0mm;
        }

        @media all {
            .page-break {
                display: block;
            }
            .pagebreak {

            }
        }

        @media print {
            html, body {
                height: initial !important;
                overflow: initial !important;
                -webkit-print-color-adjust: exact;
            }

            .pagebreak {
                page-break-before: always;
            }
            
            .page-break {
                margin-top: 1rem;
                display: block;
                page-break-before: auto;
            }
        }`;

    // loading
    if (isLoading || loading) { return <Loading getHeight={'80vh'} /> }

    return (
        <>

            {/* breadcrumb */}
            <div className="page-header">
                <div className="card breadcrumb-card">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-6">
                            <h3 className="page-title">Order</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard/orders">Orders</Link>
                                </li>
                                <li className="breadcrumb-item active-breadcrumb">
                                    <Link to={`/dashboard/order/${orderId}`}>{order?.order.invoiceId} Order</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-5">
                            <div className='text-end me-2'>
                                <ReactToPrint
                                    trigger={() => <button type='button' className='btn print-btn me-3'>Print this out!</button>}
                                    content={() => componentRef.current}
                                    pageStyle={pageStyle}
                                />
                                {
                                    order?.order.due ? <>
                                        <button className='btn primary-btn me-3' type='submit' onClick={handleDuePaid}>Due Paid</button>
                                        <button className='btn primary-btn' type='submit' onClick={handleAddCashShow}>Add Cash</button>
                                    </> : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* breadcrumb */}


            {/* main content */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card py-4 px-3">


                        {/* print elements */}
                        <div className=' mt-4' style={{ border: '1px solid grey' }}>
                            <div className="" ref={componentRef} >

                                <PrintOrder
                                    order={order}
                                />

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* main content */}


            {/* modals */}
            <AddCashModal
                refetch={refetch}
                order={order?.order}
                cashModifyData={cashModifyData}
                addCashshow={addCashshow}
                setAddCashShow={setAddCashShow}
                setLoading={setLoading}
                handleAddCashClose={handleAddCashClose}
            />
        </>
    );
};

export default ViewOrder;