import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';

const AddCashModal = ({ refetch, order, addCashshow, handleAddCashClose, setLoading, setAddCashShow, cashModifyData }) => {

    const time = new Date();
    const [addCash, setAddCash] = useState(0);

    const handleAddCash = e => {
        e.preventDefault();
        if(parseFloat(addCash) >= order.due){
            toast.error( "Cash value should be less than due");
        }else if(parseFloat(addCash) <= 0){
            toast.error( "Cash value should be greater than 0");
        }else{
            const newPaidCash = parseFloat(addCash);
            const newCash = order.cash + parseFloat(addCash);
            const newDue = order.due - parseFloat(addCash);
    
            const data = { newCash: newCash, newPaidCash: newPaidCash, newDue: newDue, userId: order?.userId?._id, createdAt: time.toString() };
     
            setLoading(true);
            cashModifyData(data, true);
        }
    }

    return (
        <Modal show={addCashshow} onHide={handleAddCashClose}>
            
            <Modal.Header closeButton>
                <Modal.Title>Add Cash [{order.invoiceId}]</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="col-sm-12">
                    <label htmlFor="restockQuantity" className="form-label">Amount to cash<span className='text-danger'>*</span></label>
                    <input type="number" className="form-control" id="addCash" placeholder="Amount of cash"
                        value={addCash}
                        required
                        onChange={(e) => setAddCash(e.target.value)}
                    />
                </div>
            </Modal.Body>

            <Modal.Footer>
                <button className='btn btn-secondary' onClick={handleAddCashClose}> Close </button>
                <button className='btn primary-btn' onClick={handleAddCash}> Add Cash </button>
            </Modal.Footer>

        </Modal>
    );
};

export default AddCashModal;