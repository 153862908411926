import React from 'react';
import { IoHomeOutline, IoBarcode } from "react-icons/io5"
import { BiCategory } from "react-icons/bi"
import { FaUsers } from "react-icons/fa"
import { FiUsers } from "react-icons/fi"
import { AiOutlineDeliveredProcedure, AiOutlineStock } from "react-icons/ai"
import { MdOutlineProductionQuantityLimits } from "react-icons/md"
import { IoMdReturnLeft } from "react-icons/io"
import useAuth from '../../../../hooks/useAuth';
import { Link } from 'react-router-dom';

const Menu = () => {

    const currentPath = window.location.pathname
    const slicePath = currentPath.slice(0, 19);
    const { productList, categoryList, orderList, userList, roleList } = useAuth();

    const linkDatas = [
        {
            name: 'Dashboard',
            icon: <IoHomeOutline />,
            indexPath: '/dashboard',
            viewPath: '',
            addPath: '',
            editPath: '',
            permission: true,
        },
        {
            name: 'Categories',
            icon: <BiCategory />,
            indexPath: '/dashboard/categories',
            viewPath: '',
            addPath: '/dashboard/add-category',
            editPath: '/dashboard/edit-cat', //dynamic id pass so need to slice
            permission: categoryList,
        },
        {
            name: 'Products',
            icon: <MdOutlineProductionQuantityLimits />,
            indexPath: '/dashboard/products',
            viewPath: '',
            addPath: '/dashboard/add-product',
            editPath: '/dashboard/edit-pro',
            permission: productList,
        },
        // {
        //     name: 'Orders',
        //     icon: <AiOutlineDeliveredProcedure />,
        //     indexPath: '/dashboard/orders',
        //     addPath: '',
        //     viewPath: '/dashboard/order/vi', //dynamic id pass so need to slice
        //     editPath: '',
        //     permission: orderList,
        // },
        // {
        //     name: 'Stocks',
        //     icon: <AiOutlineStock />,
        //     indexPath: '/dashboard/stocks',
        //     viewPath: '',
        //     addPath: '',
        //     editPath: '',
        //     permission: productList,
        // },
        // {
        //     name: 'Returns',
        //     icon: <IoMdReturnLeft />,
        //     indexPath: '/dashboard/returns',
        //     viewPath: '',
        //     addPath: '/dashboard/add-return',
        //     editPath: '',
        //     permission: productList,
        // },
        {
            name: 'Users',
            icon: <FaUsers />,
            indexPath: '/dashboard/users',
            viewPath: '/dashboard/view-use',
            accountPath: '/dashboard/user/acc',
            addPath: '/dashboard/add-user',
            editPath: '/dashboard/edit-use',
            permission: userList,
        },
        // {
        //     name: 'Suppliers',
        //     icon: <FiUsers />,
        //     indexPath: '/dashboard/suppliers',
        //     viewPath: '',
        //     accountPath: '/dashboard/supplier',
        //     addPath: '/dashboard/suppliers/add-order',
        //     editPath: '',
        //     permission: userList,
        // },
        // {
        //     name: 'Roles',
        //     icon: <IoBarcode />,
        //     indexPath: '/dashboard/roles',
        //     viewPath: '',
        //     addPath: '/dashboard/add-role',
        //     editPath: '/dashboard/edit-rol',
        //     permission: roleList,
        // },
    ]

    return (
        <>
            {
                linkDatas.map((item, index) => item.permission && <Link className={`nav-link ${(
                    currentPath === item.indexPath ||
                    currentPath === item.addPath ||
                    slicePath === item.viewPath ||
                    slicePath === item.accountPath ||
                    slicePath === item.editPath) && 'active'
                    }`}
                    to={item.indexPath} key={index}>
                    <div className={`menu-item p-1 d-flex align-items-center`} >
                        <div className="menu_icon">{item.icon}</div>
                        <div className="menu_name px-2">{item.name}</div>
                    </div>
                </Link>)
            }
        </>
    );
};

export default Menu;