import React from 'react';
import { Container, Row } from 'react-bootstrap';
import './loading.css'

const Loading = ({ getHeight }) => {
    return (
        <Container>
            <Row className='justify-content-center align-items-center'
                style={{ height: getHeight !== undefined ? getHeight : '100vh' }}
            >
                <span className="loader"></span>
            </Row>

        </Container>
    );
};

export default Loading;