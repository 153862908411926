import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import Loading from '../../Shared/Loading/Loading';
import OffcanvasCartItem from './OffcanvasCartItem';
import useCart from '../../../hooks/useCart';
import './Offcanvas.css';

const OffcanvasCart = ({ setShow }) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { cartItems, cartCount, subTotal, cartLoading, cartFetch } = useCart();
 
    // going for checkout
    const handleProccedCheckout = () => {
        setShow(false);
        navigate('/dashboard/checkout')
    }

    // loading
    if(cartLoading || loading){ return <Loading getHeight={'80vh'} /> }

    return (
        <div className='offcanvas_container'>
            <div className="offcanvas_cart_products">
                {
                    cartItems?.cartItems.map(product => <OffcanvasCartItem 
                        key={product._id} 
                        data={product} 
                        cartFetch={cartFetch}
                        setLoading={setLoading}
                    />)
                }

            </div>
            <div className="offcanvas_subtotal">
                <div className="p-2 d-flex align-items-center justify-content-between ">
                    <h4 >Subtotal</h4>
                    <h4 className="subtotal primary-text">
                        { subTotal } BDT
                    </h4>
                </div>
                <div className="my-3">
                    <button className='btn w-100 primary-btn' style={{ border: 'none' }} disabled={!cartCount} onClick={handleProccedCheckout}>
                        <div className='d-flex align-items-center justify-content-center' style={{ padding: '1px 0' }}>
                            <span> Checkout &nbsp;&nbsp;</span> 
                            <span className='h4 position-relative'style={{ top: '2px' }}><HiOutlineArrowNarrowRight/></span> 
                        </div>
                    </button>
                </div>

            </div>


        </div>
    );
};

export default OffcanvasCart;