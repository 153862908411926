import React from 'react';
import useAuth from '../../../../hooks/useAuth';
import PermissionDenied from '../PermissionDenied/PermissionDenied';

const RequiredOrderList = ({ children }) => {

    const { orderList } = useAuth();
  
    if(!orderList) { 
        return <PermissionDenied /> 
    }
    
    return children;
};

export default RequiredOrderList;