import React from 'react';

const Input = ({
    label, type, inputId, placeholder, inputValue, isRequired, errorValue, onChangeData, isDisabled, isTextarea, colSize
}) => {
    return (
        <div className={`col-sm-${colSize || '6'}`}>

            <label htmlFor={inputId} className="form-label">
                { label }
                { isRequired && <span className='text-danger'>*</span> }
            </label>

            {
                isTextarea ? <>
                                <textarea 
                                    type={type} 
                                    className="form-control custom_style" 
                                    id={inputId} 
                                    placeholder={placeholder} 
                                    rows="5"
                                    value={inputValue}
                                    onChange={onChangeData}
                                ></textarea>
                </> : <>
                    <input 
                        type={type} 
                        className="form-control custom_style" 
                        id={inputId} 
                        placeholder={placeholder}
                        value={inputValue}
                        required={isRequired}
                        disabled={isDisabled}
                        onChange={onChangeData} 
                    />
                </>
            }
           
            {
                errorValue && <p className='m-0 mt-1 text-danger'> {errorValue} </p>
            }
        </div>
    );
};

export default Input;