import React from 'react';
import ReactToPrint from 'react-to-print';
import { printPageStyle } from '../../../hooks/helpers';
import PrintElement from './PrintElement';

const Print = ({ componentRef, orderConfirm, cartItems, subTotal, total }) => {

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card py-4 px-3">

                    <div className="row justify-content-end">
                        <div className="col-md-2 text-end me-1">
                            <ReactToPrint
                                trigger={() => <button type='button' className='btn print-btn text-end'>Print this out!</button>}
                                content={() => componentRef.current}
                                pageStyle={printPageStyle}
                            />
                        </div>
                    </div>

                    {/* print elements */}
                    <div className=' mt-4' style={{ border: '1px solid grey' }}>
                        <div className="cartItems" ref={componentRef} >

                            <PrintElement
                                orderConfirm={orderConfirm}
                                cartItems={cartItems}
                                subTotal={subTotal}
                                total={total}
                            />

                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Print;