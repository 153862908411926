import React, { useState } from 'react';
import Loading from '../../Shared/Loading/Loading';
import PosProductLoop from './PosProductLoop';
import Error from '../../Shared/Error/Error';
import useProducts from '../../../hooks/useProducts';
import Breadcrumb from '../../../Utilities/Breadcrumb';
import './Product.css';
import SearchFormLive from '../../../Utilities/SearchFormLive';
import { useEffect } from 'react';


const Products = () => {

    const [searchText, setSearchText] = useState('');
    const [cartLoading, setCartLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [restockLoading, setRestockLoading] = useState(false);
    const [filterData, setFilterData] = useState([]);

    // load all products
    const { products, productsLoading, productsFetch, productsError } = useProducts('');

    // filter data by search text
    useEffect(() => {
        if (searchText) {
            setFilterData(products?.products?.filter((el) => (el.name)?.match(new RegExp(searchText, "i"))));
        } else {
            setFilterData(products?.products);
        }
    }, [searchText, products?.products]);

    // loading || error
    if (productsError) { return <Error /> }
    if (productsLoading || deleteLoading) { return <Loading getHeight={'80vh'} /> }

    return (
        <div>

            {/* ================== breadcrumb ==================== */}
            <Breadcrumb
                indexTitle={'Products'}
                indexLink={'/dashboard/products'}
                adressLink={'Add New Product'}
                address={'/dashboard/add-product'}
            />
            {/* ================================================== */}


            {/* main content */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card py-4 px-3">

                        <div className="search-box d-flex justify-content-end mt-3">
                            <SearchFormLive
                                placeholder={`Type product name & hit enter`}
                                handleChange={(e) => setSearchText(e.target.value)}
                            />
                        </div>

                        <div className="products mt-5">
                            <div className="row gy-3">
                                {filterData?.length ? filterData.map((product) => <PosProductLoop
                                    key={product._id}
                                    product={product}
                                    setDeleteLoading={setDeleteLoading}
                                    setCartLoading={setCartLoading}
                                    cartLoading={cartLoading}
                                    restockLoading={restockLoading}
                                    setRestockLoading={setRestockLoading}
                                    productsFetch={productsFetch}
                                />) : <p className='text-center mt-5'>No record found!</p>}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* main content */}
        </div>
    );
};

export default Products;