import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import toast from "react-hot-toast";

const useCategories = (searchText) => {
    const navigate = useNavigate();

    const { data: categories, isLoading, refetch } = useQuery(['allCategories', searchText], () =>
    fetch(`${process.env.REACT_APP_SERVER_URL}product/category/index?name=${searchText}`, {
        headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
    })
        .then(res => {
            if (res.status === 401 || res.status === 401) {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('userId');
                navigate('/login');
                toast.error('Forbidden/Unauthorized access!', { duration: 2000, position: 'top-right', });
            }
            return res.json();
        }))

    return { categories, categoriesLoading: isLoading, categoriesFetch: refetch };
}

export default useCategories;