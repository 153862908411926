import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const useCart = () => {
    const navigate = useNavigate();
    const [cartCount, setCartCount] = useState(0);
    const [subTotal, setSubTotal] = useState(0);

    const { data: cartItems, isLoading, refetch } = useQuery(['cartItems'], () =>
    fetch(`${process.env.REACT_APP_SERVER_URL}cart/index`, {
        headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
    })
        .then(res => {
            if (res.status === 401 || res.status === 401) {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('userId');
                navigate('/login');
                toast.error('Forbidden/Unauthorized access!', { duration: 2000 });
            }
            return res.json();
        })) 

    // set cart item count
    useEffect(() => {
        if(cartItems?.success === true){
            setCartCount(cartItems?.cartItems.length);
            setSubTotal(cartItems?.cartItems.reduce((total, item)=>total+(item.orderPrice*item.orderQuantity),0));
        }
    }, [cartItems])

    return { cartItems, cartCount, subTotal, cartLoading: isLoading, cartFetch: refetch };
}

export default useCart;