import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Breadcrumb from '../../../Utilities/Breadcrumb';
import Input from '../../../Utilities/Input';
import Select from '../../../Utilities/Select';

const AddCategory = () => {

    const navigate = useNavigate();
    const [categoryData, setCategoryData] = useState({  name: '', status: 'active' });
    const [errors, setError] = useState({ name: '', status: '' });
    const [loading, setLoading] = useState(false);
  
    // add new category
    const handleAddCategory = (e) => {
        e.preventDefault();

        if (categoryData.name === '' && categoryData.status === '') {
            setError({ ...errors, name: 'Category name is required.', status: 'Status is required.' })
        } else if (categoryData.name === '') {
            setError({ ...errors, name: 'Category name is required.', status: '' })
        } else if (categoryData.status === '') {
            setError({ ...errors, name: '', status: 'Status is required.' })
        } else {
            setLoading(true);

            fetch(`${process.env.REACT_APP_SERVER_URL}product/category/create`, {
                method: 'POST',
                headers: {
                    'content-Type': 'application/json',
                    authorization: `Bearer ${localStorage.getItem('accessToken')}`
                },
                body: JSON.stringify(categoryData)
            })
                .then(res => {
                    if (res.status === 401 || res.status === 403) {
                        localStorage.removeItem('accessToken');
                        localStorage.removeItem('userId');
                        navigate('/login');
                        toast.error('Forbidden/Unauthorized access!');
                    }
                    return res.json();
                })
                .then(data => {
                    setLoading(false);
                    if (data.message && data.success === true) {
                        setCategoryData({ name: '', status: 'active' });
                        setError({ ...errors, name: '', status: '' });
                        toast.success(data.message);
                    }
                    if (data.message && data.success === false) {
                        toast.error(data.message);
                    }
                })
        }
    }

    return (
        <form onSubmit={handleAddCategory}>
            
            {/* ================== breadcrumb ==================== */}
            <Breadcrumb
                indexTitle={'Categories'}
                indexLink={'/dashboard/categories'}
                createPageTitle={'Add Category'}
                createPageLink={'/dashboard/add-category'}
                btnTitle={'Add Category'}
                loading={loading}
            />
            {/* ================================================== */}


            {/* main content */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card py-4 px-3">

                        <h4>Add Category</h4>
                        <hr />


                        <div className="row gx-5 gy-3">

                            <Input 
                                label={'Category Name'} type={'text'} inputId={'name'}
                                placeholder={'Enter category name'} isRequired={true}
                                inputValue={categoryData?.name} errorValue={errors.name}
                                onChangeData={(e) => setCategoryData({ ...categoryData, name: e.target.value })}
                            />

                            <Select 
                                label={'Status'} isRequired={true} inputId={'status'}
                                inputValue={categoryData.status} errorValue={errors.status}
                                onChangeData={(e) => setCategoryData({ ...categoryData, status: e.target.value })}
                                initialOptionTitle={'Select status'}
                                options={[
                                    {'name': 'Active', 'value': 'active'},
                                    {'name': 'Inactive', 'value': 'inactive'}
                                ]}
                            />

                        </div>
                    </div>
                </div>
            </div>
            {/* main content */}
        </form>
    );
};

export default AddCategory;