import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const useDelete = () => {

    const navigate = useNavigate();
  
    const sendDeleteRequest = async (fetchAPI, setLoading, refetch) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this imaginary file!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                setLoading(true);
                const deleteConfirm = async () => {
                    fetch(fetchAPI, {
                        method: 'DELETE',
                        headers: {
                            authorization: `Bearer ${localStorage.getItem('accessToken')}`
                        }
                    })
                        .then(res => {
                            if (res.status === 401 || res.status === 403) {
                                localStorage.removeItem('accessToken');
                                localStorage.removeItem('userId');
                                navigate('/login');
                                toast.error('Forbidden/Unauthorized access!');
                            }
                            return res.json();
                        })
                        .then(data => {
                            setLoading(false);
                            if (data.message && data.success === true) {
                                refetch();
                                toast.success(data.message);
                            }
                            if (data.message && data.success === false) {
                                toast.error(data.message);
                            }
                        })
                }
                deleteConfirm();
            }
        });
    }

    return { sendDeleteRequest };
}

export default useDelete;