import React from 'react';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../../../Utilities/Breadcrumb';
import DashboardCard from './DashboardCard/DashboardCard';
import Loading from '../../Shared/Loading/Loading';

import due_icon from "../../../assets/images/due.svg";
import product_icon from "../../../assets/images/product.svg";
import todays_total_icon from "../../../assets/images/todays-total.svg";
import total_cash_icon from "../../../assets/images/total-cash.svg";
import total_user_icon from "../../../assets/images/total-user.svg";

const DashboardContent = () => {

    const navigate = useNavigate();

    // get all dashboard (dont distructure it will conflict other data)
    const { data: dashboard, isLoading } = useQuery(['dashboard'], () =>
        fetch(`${process.env.REACT_APP_SERVER_URL}dashboard/index`, {
            headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
            .then(res => {
                if (res.status === 401 || res.status === 403) {
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('userId');
                    navigate('/login');
                    toast.error('Forbidden/Unauthorized access!');
                }
                if (res.status === 500) {
                    toast.error('Server error!');
                }
                return res.json();
            }))

    // loading
    if (isLoading) { return <Loading getHeight={'80vh'} /> }

    return (
        <div>

            {/* ================== breadcrumb ==================== */}
            <Breadcrumb />
            {/* ================================================== */}


            {/* main content */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card py-4 px-3">
                        <div class="tree">
                            <ul>
                                <li>
                                    <span class="root">A</span>
                                    <ul>
                                        <li>
                                            <span>B</span>
                                            <ul>
                                                <li><span>D</span></li>
                                                <li><span>E</span></li>
                                                <li><span>F</span></li>
                                            </ul>
                                        </li>
                                        <li>
                                            <span>C</span>
                                            <ul>
                                                <li><span>E</span></li>
                                                <li><span>F</span></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* main content */}

        </div>
    );
};

export default DashboardContent;