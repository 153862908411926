import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select'
import useProducts from '../../../hooks/useProducts';
import useUsers from '../../../hooks/useUsers';
import Breadcrumb from '../../../Utilities/Breadcrumb';
import Loading from '../../Shared/Loading/Loading';
import toast from 'react-hot-toast';
import Input from '../../../Utilities/Input';

const AddReturn = () => {

    const navigate = useNavigate();

    // get users & products
    const { users, usersLoading } = useUsers('');
    const { products, productsLoading } = useProducts('');

    const [returnData, setReturnData] = useState({ userId: '', email: '', productId: '', productQuantity: 0, quantity: 0, returnValue: 0 });
    const [loading, setLoading] = useState(false);

    // select user to get value
    const hanldeUserChange = (newValue) => {
        setReturnData({ ...returnData, userId: newValue._id, email: newValue.email })
    }
    const hanldeProductChange = (newValue) => {
        setReturnData({ ...returnData, productId: newValue._id, productQuantity: newValue.quantity })
    }

    // select style
    const style = {
        control: (base, state) => ({
            ...base,
            boxShadow: state.isFocused ? 0 : 0,
        }),
        option: (provided, state) => ({
            ...provided,
            padding: '16px 15px',
            cursor: 'pointer',
            borderBottom: '1px solid #E9EBEB',
            backgroundColor: 'transparent',
            color: state.isSelected ? 'black' : 'black',
            '&:hover': {
                backgroundColor: '#008080',
                color: 'white',
            }
        }),
    };

    const handleAddReturn = (e) => {
        e.preventDefault();

        if(returnData.userId === '' || returnData.productId === ''){
            toast.error('All fileds are required!');
        }else{
            setLoading(true);
            fetch(`${process.env.REACT_APP_SERVER_URL}return/create`, {
                method: 'POST',
                headers: {
                    'content-Type': 'application/json',
                    authorization: `Bearer ${localStorage.getItem('accessToken')}`
                },
                body: JSON.stringify(returnData)
            })
                .then(res => {
                    if (res.status === 401 || res.status === 403) {
                        localStorage.removeItem('accessToken');
                        localStorage.removeItem('userId');
                        navigate('/login');
                        toast.error('Forbidden/Unauthorized access!');
                    }
    
                    return res.json();
                })
                .then(data => {
                    setLoading(false);
                    if (data.message && data.success === true) {
                        setReturnData({ userId: '', productId: '', quantity: 0, returnValue: 0 });
                        toast.success(data.message);
                    }
                    if (data.message && data.success === false) {
                        toast.error(data.message);
                    }
                })
        }
    }

    // loading
    if (usersLoading || productsLoading) { return <Loading getHeight={'80vh'} /> }

    return (
        <form onSubmit={handleAddReturn}>

            {/* ================== breadcrumb ==================== */}
            <Breadcrumb
                indexTitle={'Returns'}
                indexLink={'/dashboard/returns'}
                createPageTitle={'Return Product'}
                createPageLink={'/dashboard/add-return'}
                btnTitle={'Return Product'}
                loading={loading}
            />
            {/* ================================================== */}


            {/* main content */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card py-4 px-3">

                        <h4>Return a product</h4>
                        <hr />


                        <div className="row gx-5 gy-3">

                            <div className="col-sm-6">
                                <label htmlFor="userId" className="form-label">Customer Id<span className='text-danger'>*</span></label>

                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    styles={style} required
                                    options={users?.users}
                                    getOptionLabel={(option) => `${option.name}: ${option.email}`}
                                    onChange={hanldeUserChange}
                                />
                            </div>

                            <div className="col-sm-6">
                                <label htmlFor="productId" className="form-label">Product<span className='text-danger'>*</span></label>

                                <Select
                                    options={products?.products}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    styles={style} required
                                    getOptionLabel={(option) => `${option.name}`}
                                    onChange={hanldeProductChange}
                                />
                            </div>

                            <Input
                                label={'Quantity'} type={'number'} inputId={'quantity'}
                                placeholder={'Enter quantity'} isRequired={true}
                                inputValue={returnData?.quantity}
                                onChangeData={(e) => setReturnData({ ...returnData, quantity: e.target.value && parseInt(e.target.value) })}
                            />

                            <Input
                                label={'Return Value'} type={'number'} inputId={'returnValue'}
                                placeholder={'Enter return amount'} isRequired={true}
                                inputValue={returnData?.returnValue}
                                onChangeData={(e) => setReturnData({ ...returnData, returnValue: e.target.value && parseFloat(e.target.value) })}
                            />

                        </div>
                    </div>
                </div>
            </div>
            {/* main content */}
        </form>
    );
};

export default AddReturn;