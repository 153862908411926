import React, { useState } from 'react';
import { IoTrash } from 'react-icons/io5';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../../../Utilities/Breadcrumb';
import Loading from '../../Shared/Loading/Loading';
import DataTable from 'react-data-table-component';
import toast from 'react-hot-toast';
import useDelete from '../../../hooks/useDelete';
import SearchForm from '../../../Utilities/SearchForm';

const Return = () => {

    const navigate = useNavigate();
    const { sendDeleteRequest } = useDelete();
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);

    // get all returns
    const { data: returns, isLoading, refetch } = useQuery(['returns', searchText], () =>
        fetch(`${process.env.REACT_APP_SERVER_URL}return/index?email=${searchText}`, {
            headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
            .then(res => {
                if (res.status === 401 || res.status === 403) {
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('userId');
                    navigate('/login');
                    toast.error('Forbidden/Unauthorized access!');
                }
                if (res.status === 500) {
                    toast.error('Forbidden/Unauthorized access!');
                }
                return res.json();
            }))

    // set search text
    const handleSearchText = (e) => {
        e.preventDefault();
        setSearchText(e.target.search.value);
    }

    // delete return data
    const handleReturnDelete = (returnId) => {
        sendDeleteRequest(`${process.env.REACT_APP_SERVER_URL}return/${returnId}`, setLoading, refetch);
    }

    // datas
    const columns = [
        {
            name: 'Product Name',
            selector: row => row.productId?.name,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Return By',
            selector: row => <span>{row.userId?.name}</span>,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Return By',
            selector: row => <span>{row.email}</span>,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Quantity',
            selector: row => <span>{row.quantity} PC</span>,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Total',
            selector: row => <span>{row.returnValue} ৳</span>,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Date',
            selector: row => row.createdAt.substring(0, 10),
            filterable: true,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => (
                <>
                    <button className='btn btn-danger' onClick={() => handleReturnDelete(row._id)}><IoTrash /></button>
                </>
            ),
        },
    ];

    if (isLoading || loading) { return <Loading getHeight={'80vh'} /> }

    return (
        <div>

            {/* ================== breadcrumb ==================== */}
            <Breadcrumb
                indexTitle={'Returns'}
                indexLink={'/dashboard/returns'}
                adressLink={'Add New Return'}
                address={'/dashboard/add-return'}
            />
            {/* ================================================== */}


            {/* main content */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card py-4 px-3">

                        <DataTable
                            columns={columns}
                            data={returns?.returns}
                            highlightOnHover
                            progressPending={isLoading}
                            pagination
                            paginationPerPage={15}
                            subHeader
                            subHeaderComponent={
                                <SearchForm 
                                    searchText={searchText}
                                    handleSearchText={handleSearchText}
                                    placeholder={'Type user name & hit enter'}
                                />
                            }
                        />

                    </div>
                </div>
            </div>
            {/* main content */}
        </div>
    );
};

export default Return;