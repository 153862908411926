import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../../../Utilities/Breadcrumb';

const AddRole = () => {

    const navigate = useNavigate();
    const [checked, setChecked] = useState([]);
    const [loading, setLoading] = useState(false);
    const [roleData, setRoleData] = useState({ name: '', permissionId: [] });
    const [errors, setError] = useState({ name: '', permissionId: '' });

    // setting permissions
    const permissions = [
        {
            name: 'dashboard-list'
        },
        {
            name: 'user-list'
        },
        {
            name: 'role-list'
        },
        {
            name: 'category-list'
        },
        {
            name: 'product-list'
        },
        {
            name: 'cart-list'
        },
        {
            name: 'order-list'
        },
    ]

    // Add/Remove checked item from list
    const checkHandler = (event) => {
        var updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, event.target.value];
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
        }
        setChecked(updatedList);
    };

    // add new role
    const handleAddRole = (e) => {
        e.preventDefault();
        const postData = { ...roleData, permissionId: checked }

        if (postData.name === '' && postData.permissionId.length === 0) {
            setError({ ...errors, name: 'Role name filed is required.', permissionId: 'You have select atleast one permission.' })
        } else if (postData.name === '') {
            setError({ ...errors, name: 'Role name filed is required.', permissionId: '' })
        } else if (postData.permissionId.length === 0) {
            setError({ ...errors, name: '', permissionId: 'You have select atleast one permission.' })
        } else {
            setLoading(true);

            fetch(`${process.env.REACT_APP_SERVER_URL}role/create`, {
                method: 'POST',
                headers: {
                    'content-Type': 'application/json',
                    authorization: `Bearer ${localStorage.getItem('accessToken')}`
                },
                body: JSON.stringify(postData)
            })
                .then(res => {
                    if (res.status === 401 || res.status === 403) {
                        localStorage.removeItem('accessToken');
                        localStorage.removeItem('userId');
                        navigate('/login');
                        toast.error('Forbidden/Unauthorized access!', { duration: 2000, position: 'top-right', });
                    }

                    if (res.status === 500) {
                        toast.success('Server error!', { duration: 2000, position: 'top-right', });
                    }

                    return res.json();
                })
                .then(data => {
                    setLoading(false);
                    if (data.message && data.success === true) {
                        setChecked([]);
                        setRoleData({ name: '', permissionId: [] });
                        setError({ ...errors, name: '', permissionId: '' });
                        toast.success(data.message, { duration: 2000, position: 'top-right', });
                    }
                    if (data.message && data.success === false) {
                        toast.error(data.message, { duration: 2000, position: 'top-right', });
                    }
                })
        }
    }

    return (
        <form onSubmit={handleAddRole}>

            {/* ================== breadcrumb ==================== */}
            <Breadcrumb
                indexTitle={'Roles'}
                indexLink={'/dashboard/roles'}
                createPageTitle={'Add Role'}
                createPageLink={'/dashboard/add-role'}
                btnTitle={'Add Role'}
                loading={loading}
            />
            {/* ================================================== */}

            {/* main content */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card py-4 px-3">

                        <h4>Add New Role</h4>
                        <hr />


                        <div className="row gx-5 gy-3">


                            <div className="col-sm-12">
                                <label htmlFor="name" className="form-label">Role Name<span className='text-danger'>*</span></label>
                                <input type="text" className="form-control" id="name" placeholder="User Name"
                                    value={roleData?.name}
                                    required={!roleData.name}
                                    onChange={(e) => setRoleData({ ...roleData, name: e.target.value })} />
                                {
                                    errors.name && <p className='m-0 mt-2 text-danger'> {errors.name} </p>
                                }

                                <hr />
                            </div>

                            {/* all permissions */}
                            <div className="permissions">
                                <div className="row gy-3">
                                    {
                                        permissions.map((permission, index) => <div key={index} className="col-md-2">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox"
                                                    onClick={checkHandler}
                                                    value={permission.name} id={permission.name}
                                                />
                                                <label className="form-check-label" htmlFor={permission.name}>
                                                    {permission.name}
                                                </label>
                                            </div>
                                        </div>)
                                    }

                                    {
                                        errors.permissionId && <p className='m-0 mt-4 text-danger'> {errors.permissionId} </p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* main content */}
        </form>
    );
};

export default AddRole;