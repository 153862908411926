import React from 'react';

const PermissionDenied = () => {
    return (
        <div>
            tmi aikhane ki koro miya tmr to permission nai
        </div>
    );
};

export default PermissionDenied;