import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import toast from "react-hot-toast";

const useUser = (userId) => {
    const navigate = useNavigate();

    const { data: user, isLoading, refetch } = useQuery(['user', userId], () =>
        fetch(`${process.env.REACT_APP_SERVER_URL}user/${userId}`, {
            headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
            .then(res => {
                if (res.status === 401 || res.status === 401) {
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('userId');
                    navigate('/login');
                    toast.error('Forbidden/Unauthorized access!', { duration: 2000 });
                }
                return res.json();
            }), {enabled: userId.length > 0})

    return { user, userLoading: isLoading, userFetch: refetch };
}

export default useUser;