import React from 'react';
import { Container, Dropdown, Navbar } from 'react-bootstrap';
import { IoCartOutline, IoChevronDown } from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../../../hooks/useAuth';
import useCart from '../../../../hooks/useCart';
import icon from '../../../../assets/icon.png';
import { IoPower } from "react-icons/io5";
import userDemo from '../../../../assets/images/user-demo.png'

const DashboardHeader = ({ handleToggle, toggleClassState, handleShow }) => {

    const navigate = useNavigate();
    const { loggedUser, cartList } = useAuth();
    const { cartCount } = useCart();

    // logout
    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userId');
        navigate('/login');
    }

    return (
        <header className='sticky-top'>
            <Navbar className="navbar navbar-light bg-light flex-md-nowrap py-0 shadow-sm" expand="lg">
                <Container fluid>

                    <Navbar.Brand>
                        <Link to={'/dashboard'}>
                            Dream International
                        </Link>
                    </Navbar.Brand>

                    <div className="d-flex align-items-center">

                        {/* {cartList && <button variant='light' className='btn' onClick={handleShow}>
                            <div id="cart" className='cart'>
                                <span id='cart-count'>{cartCount}</span>
                                <span className='cart-icon '><IoCartOutline /></span>
                            </div>
                        </button>} */}

                        <Dropdown className="d-inline mx-2 user-state-dropdown">
                            <Dropdown.Toggle variant="outline-light" id="dropdown-autoclose-true" className='avater-wrapper outline-none'>
                                <div className="avater-image text-primary">
                                    <img className='user-avater' width={40}
                                        src={loggedUser?.name ? `https://ui-avatars.com/api/?name=${loggedUser?.name.split(" ").join("+")}` : `${userDemo}`}
                                        // src={loggedUser?.img ? `${process.env.REACT_APP_SERVER_URL}${loggedUser?.img}` : `${userDemo}`}
                                        alt={loggedUser?.name ? loggedUser?.name : 'Tamim'} />
                                    <span className="username">{loggedUser ? loggedUser.name : 'Tamim'}</span>  <span><IoChevronDown /></span>
                                </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {/* <Dropdown.Item href="#">Edit Profile</Dropdown.Item> */}
                                <Dropdown.Item className='Logout' href="#" onClick={handleLogout}><IoPower /> Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        <div className='humb_menu' onClick={handleToggle}>
                            <div className={toggleClassState ? "toggle_icon" : "toggle_icon open"}>
                                <span></span><span></span><span></span>
                            </div>
                        </div>
                    </div>

                </Container>
            </Navbar>
        </header>
    );
};

export default DashboardHeader;