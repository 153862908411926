import React from 'react';
import useAuth from '../../../../hooks/useAuth';
import PermissionDenied from '../PermissionDenied/PermissionDenied';

const RequiredCartList = ({ children }) => {

    const { cartList } = useAuth();
  
    if(!cartList) { 
        return <PermissionDenied /> 
    }
    
    return children;
};

export default RequiredCartList;