import React, { memo, useEffect, useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { Outlet, useNavigate } from 'react-router-dom'
import useAuth from '../../../hooks/useAuth';
import OffcanvasCart from '../Cart/Offcanvas';
import './dashboar.css';
import './menu.css'
import decode from 'jwt-decode'
import DashboardHeader from './components/DashboardHeader';
import DashboardSidebar from './components/DashboardSidebar';

const Dashboard = () => {
 
    let navigate = useNavigate();

    // get logged user info & cart info
    const { logged } = useAuth();

    // toggle_icon
    const [toggleClassState, setToggleClassState] = useState("false");
    const [toggleCollapseState, setToggleCollapseState] = useState("false");
    const handleToggle = () => setToggleClassState(!toggleClassState);
    const handleToggleCollapse = () => setToggleCollapseState(!toggleCollapseState);

    // show cart modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () =>  setShow(true);

    // if jwt token expeires
    useEffect(() => {
        const getToken = localStorage.getItem('accessToken');

        if(getToken){
            const decodedToken = decode(getToken);

            if(decodedToken.exp * 1000 < new Date().getTime){
                localStorage.removeItem('accessToken');
                localStorage.removeItem('userId');
                navigate('/login');
            }
        }
    },[navigate])

    // if(loggedUserLoading){ return <Loading /> }

    return (
        <>
    
            <DashboardHeader 
                handleShow={handleShow}
                handleToggle={handleToggle}
                toggleClassState={toggleClassState}
            />

            <div className={toggleCollapseState ? "dashboard-skeleton" : "dashboard-skeleton collapsed"}>

                <DashboardSidebar 
                  toggleClassState={toggleClassState}
                  handleToggleCollapse={handleToggleCollapse}
                />

                <div className="main">
                    <div className="p-3">
                        {
                            // loggedUserLoading ? <Loading getHeight={'80vh'} /> : <Outlet></Outlet>
                            !logged ? <h5 className='text-danger d-flex align-items-center justify-content-center' style={{  height: '80vh' }}>No data to show</h5> : <Outlet></Outlet>
                        }
                    </div>
                </div>

            </div>

            <Offcanvas show={show} onHide={handleClose} placement='end'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Cart</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <OffcanvasCart setShow={setShow} />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};


export default memo(Dashboard);