import React, { useEffect, useRef, useState } from 'react';
import useUsers from '../../../hooks/useUsers';
import Breadcrumb from '../../../Utilities/Breadcrumb';
import Button from '../../../Utilities/Button';
import Input from '../../../Utilities/Input';
import RSelect from '../../../Utilities/RSelect';
import { useAtom } from 'jotai';
import Loading from '../../Shared/Loading/Loading';
import { supplierOrderItems } from '../../../state';
import SupplierPrint from './SupplierPrint';
import { printPageStyle, uniqueInvoiceSupplier } from '../../../hooks/helpers';
import ReactToPrint from 'react-to-print';
import toast from 'react-hot-toast';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';

const AddOrder = () => {

    const supplier = 'Supplier';
    const componentRef = useRef();
    const navigate = useNavigate();
    const today = new Date();
    const newInvoiceId = 'TMESUPP' + today.valueOf()

    // users info
    const { users, usersLoading } = useUsers('');

    // states
    const [options, setOptions] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [discount, setDiscount] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [cash, setCash] = useState(0);
    const [due, setDue] = useState(0);
    const [selectedSupplier, setSelcetedSupplier] = useState({ label: "Select Supplier", value: "" });
    const [loading, setLaoding] = useState(false);

    const [orderItems, setOrderItems] = useAtom(supplierOrderItems);
    const [orderItem, setOrderItem] = useState({ name: '', quantity: "", price: "", total: 0 });

    // select label
    const users_name = users?.users?.filter((el) => el?.role.name.toLowerCase() === supplier.toLowerCase()).map((e) => {
        return { label: `${e.name}`, value: e._id }
    }) || []

    // set select value
    useEffect(() => {
        setOptions([{ label: "Select Supplier", value: "" }, ...users_name])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users?.users])

    // set selected user info value
    useEffect(() => {
        const suppliers = users?.users?.filter((el) => el?.role.name.toLowerCase() === supplier.toLowerCase());
        setSelectedUser(suppliers?.find(item => item._id === selectedSupplier.value))
    }, [selectedSupplier.value, users?.users])

    // get total on item
    useEffect(() => {
        setOrderItem({ ...orderItem, total: parseFloat(orderItem.quantity) * parseFloat(orderItem.price) })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderItem.quantity, orderItem.price])

    // total and subtotal
    useEffect(() => {
        setSubTotal(orderItems.reduce((total, item) => total + item.total, 0))
        setTotal(subTotal - parseFloat(discount))
    }, [orderItems, subTotal, discount])

    // due
    useEffect(() => {
        setDue(total - parseFloat(cash))
    }, [total, cash])

    // add item
    const handleAddItem = () => {
        setOrderItems([...orderItems, orderItem]);
        setOrderItem({ name: '', quantity: 0, price: 0, total: 0 })
    }

    // remove all items
    const handleRemoveCart = () => {
        setOrderItems([]);
    }

    const handleAddSupplierOrder = (e) => {
        e.preventDefault();

        if(selectedSupplier.value === '' ){
            return  toast.error('Please select the supplier!');
        } 
        
        if(orderItems.length < 0 ){
            return  toast.error('Add one item atleast!');
        } 
        
        if(cash > total ){
            return  toast.error('Cash amount much than total!');
        }

        const data = {
            supplier: selectedSupplier.value, invoiceId: newInvoiceId, cash: parseFloat(cash), due,
            subTotal, discount: parseFloat(discount), total, orderProducts: orderItems, userId: selectedSupplier.value
        }

        
        const config = {
            headers: {
                authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
        };
        setLaoding(true);
        axios.post(`${process.env.REACT_APP_SERVER_URL}supplier-order/create`, data, config)
            .then(res => {
                if (res.status === 401 || res.status === 403) {
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('userId');
                    navigate('/login');
                    toast.error('Forbidden/Unauthorized access!');
                }

                if (res.status === 500 || res.data.success === false) {
                    toast.error('Server error!');
                }

                if (res.status === 200 || res.data.success === true) {
                    setOrderItems([]);
                    setSelcetedSupplier(options[0]);
                    setCash(0);
                    setDue(0);
                    setDiscount(0);
                    toast.success(res.data.message);
                }

                setLaoding(false);
            })
    }

    // loading
    if (usersLoading) { return <Loading getHeight={'80vh'} /> }

    return (
        <form encType='multipart/form-data' onSubmit={handleAddSupplierOrder}>

            {/* ================== breadcrumb ==================== */}
            <Breadcrumb
                indexTitle={'Suppliers'}
                indexLink={'/dashboard/suppliers'}
                btnTitle={'Add Supplier Order'}
                createPageTitle={'Add Supplier Order'}
                createPageLink={'/dashboard/suppliers/add-order'}
                address={'/dashboard/suppliers/add-order'}
                loading={loading}
            />
            {/* ================================================== */}

            {/* main content */}
            <div className="row mb-4">
                <div className="col-md-12">
                    <div className="card py-4 pb-5 px-3">

                        <div className='d-flex justify-content-between'>
                            <h4>Add New Supplier Order</h4>

                            <div>
                                <Button
                                    title={'Clear Cart'}
                                    css={'bg-danger me-3'}
                                    onClick={handleRemoveCart}
                                />

                                <ReactToPrint
                                    trigger={() => <button type='button' className='btn print-btn text-end'>Print this out!</button>}
                                    content={() => componentRef.current}
                                    pageStyle={printPageStyle}
                                />
                            </div>

                        </div>

                        <hr />


                        <div className="row gx-5 gy-4 mt-1">

                            <div className="row gx-5 ">
                                <div className="col-md-6 ">
                                    <label htmlFor='supplier' className="form-label">
                                        Select Supplier
                                    </label>
                                    <RSelect
                                        defaultValue={selectedSupplier}
                                        options={options}
                                        setSelectedOption={setSelcetedSupplier}
                                    />
                                </div>
                                <div className="col-md-6 ">
                                    <Input
                                        label={'Discount amount'} type={'text'} inputId={'discount'}
                                        placeholder={'Enter discount amount'} colSize={12}
                                        inputValue={discount}
                                        onChangeData={(e) => setDiscount(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-6 mt-3">
                                    <Input
                                        label={'Cash'} type={'text'} inputId={'cash'}
                                        placeholder={'Enter cash amount'} colSize={12}
                                        inputValue={cash}
                                        onChangeData={(e) => setCash(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-6 mt-3">
                                    <Input
                                        label={'Due'} type={'number'} inputId={'due'}
                                        placeholder={'0'} colSize={12}
                                        inputValue={due} isDisabled={true}
                                    />
                                </div>
                            </div>


                            <div className='mt-5'>
                                <div className='row align-items-end'>
                                    <div className='col-md-3'>
                                        <Input
                                            label={'Product name'} type={'text'} inputId={'name'}
                                            placeholder={'Enter product name'} colSize={12}
                                            inputValue={orderItem?.name}
                                            onChangeData={(e) => setOrderItem({ ...orderItem, name: e.target.value })}
                                        />
                                    </div>
                                    <div className='col-md-2'>
                                        <Input
                                            label={'Quantity'} type={'text'} inputId={'name'}
                                            placeholder={'0'} colSize={12}
                                            inputValue={orderItem?.quantity}
                                            onChangeData={(e) => setOrderItem({ ...orderItem, quantity: e.target.value })}
                                        />
                                    </div>
                                    <div className='col-md-2'>
                                        <Input
                                            label={'Price'} type={'text'} inputId={'name'}
                                            placeholder={'0'} colSize={12}
                                            inputValue={orderItem?.price}
                                            onChangeData={(e) => setOrderItem({ ...orderItem, price: e.target.value })}
                                        />
                                    </div>
                                    <div className='col-md-2'>
                                        <Input
                                            label={'Total'} type={'number'} inputId={'name'}
                                            placeholder={'0'} colSize={12} isDisabled={true}
                                            inputValue={orderItem?.total}
                                        />
                                    </div>
                                    <div className='col-md-2'>
                                        <Button type='button' title={'Add item'} css={'w-75 py-2'} onClick={handleAddItem}></Button>
                                    </div>

                                </div>
                            </div>

                        </div>

                        {/* print */}
                        {(orderItems.length && selectedSupplier.value) ? <div className='mt-5'>
                            <SupplierPrint
                                products={orderItems}
                                uniqueInvoiceSupplier={newInvoiceId}
                                data={selectedUser}
                                discount={discount}
                                subTotal={subTotal}
                                total={total}
                                cash={cash}
                                due={due}
                                componentRef={componentRef}
                            />
                        </div> : null}




                    </div>
                </div>
            </div>
            {/* main content */}


        </form>
    );
};

export default AddOrder;