import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoTrash, IoCogOutline } from "react-icons/io5";
import Loading from '../../Shared/Loading/Loading';
import useCategories from '../../../hooks/useCategories';
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../../Utilities/Breadcrumb';
import useDelete from '../../../hooks/useDelete';
import SearchForm from '../../../Utilities/SearchForm';

const Categories = () => {

    const navigate = useNavigate();
    const { sendDeleteRequest } = useDelete();
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');

    // get all categories
    const { categories, categoriesLoading, categoriesFetch } = useCategories(searchText);

    // set the searh to search query
    const handleSearchText = (e) => {
        e.preventDefault();
        setSearchText(e.target.search.value);
    }

    // navigate to edit category
    const handleEditCategory = async (categoryId) => {
        navigate(`/dashboard/edit-category/${categoryId}`)
    }

    // delete category
    const handleDeleteCategory = async (categoryId) => {
        sendDeleteRequest(`${process.env.REACT_APP_SERVER_URL}product/category/${categoryId}`, setLoading, categoriesFetch);
    }

    // data view in table
    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => (
                <>
                    <button className='btn btn-warning me-2 text-white' onClick={() => handleEditCategory(row._id)}><IoCogOutline /></button>
                    <button className='btn btn-danger' onClick={() => handleDeleteCategory(row._id)}><IoTrash /></button>
                </>
            ),
        },
    ];

    // loading
    if (categoriesLoading || loading) { return <Loading getHeight={'80vh'} /> }

    return (
        <div>
            {/* ================== breadcrumb ==================== */}
            <Breadcrumb
                indexTitle={'Categories'}
                indexLink={'/dashboard/categories'}
                adressLink={'Add New Category'}
                address={'/dashboard/add-category'}
            />
            {/* ================================================== */}


            {/* main content */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card py-4 px-3">

                        <DataTable
                            columns={columns}
                            data={categories?.categories}
                            highlightOnHover
                            progressPending={categoriesLoading}
                            pagination
                            paginationPerPage={15}
                            subHeader
                            subHeaderComponent={
                                <SearchForm
                                    searchText={searchText}
                                    handleSearchText={handleSearchText}
                                    placeholder={'Type category name & hit enter'}
                                />
                            }
                        />

                    </div>
                </div>
            </div>
            {/* main content */}
        </div>
    );
};

export default Categories;