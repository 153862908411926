import React from 'react';
import useAuth from '../../../../hooks/useAuth';
import PermissionDenied from '../PermissionDenied/PermissionDenied';

const RequiredUserList = ({ children }) => {
    const { userList } = useAuth();
  
      if(!userList) { 
          return <PermissionDenied /> 
      }
      
      return children;
};

export default RequiredUserList;