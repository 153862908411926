import React from 'react';

const Button = ({ loading, title, css, onClick, type }) => {
    return (
        <button
            className={`btn primary-btn ${css}`}
            type={`${type ? type : 'submit'}`}
            disabled={loading}
            onClick={onClick}
        >
            <div className={`${loading && 'd-flex align-items-center justify-content-end'}`}>
                {loading && <span class="custom__loader_btn me-2"></span>} {title}
            </div>
        </button>
    );
};

export default Button;