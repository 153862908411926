// date
const current = new Date();
export const date = `${current.getDate()}-${current.getMonth() + 1}-${current.getFullYear()}`;
export const uniqueInvoiceSupplier = 'TMESUPP' + current.valueOf()

export const selectStyle = {
    control: (provided, state) => ({
        ...provided,
        boxShadow: state.isFocused ? 0 : 0,
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#008080' : 'transparent',
        color: state.isSelected ? 'white' : 'initial',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: state.isSelected ? '#008080' : '#e5e7eb',
            color: state.isSelected ? 'white' : 'initial',
        },
    }),
};

export const printPageStyle = `
@page {
    size: A4;
    margin: 5mm 0mm;
}

@media all {
    .page-break {
        display: block;
      }
    .pagebreak {

    }
}

@media print {
    html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }

    .pagebreak {
        page-break-before: always;
    }
    
    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
      }
}`;

export const printPageLandscapeStyle = `
@page {
    size: landscape;
    margin: 3mm 5mm;
}

@media all {
    .page-break {
        display: block;
      }
    .pagebreak {

    }
}

@media print {
    html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }

    .pagebreak {
        page-break-before: always;
    }
    
    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
      }
}`;