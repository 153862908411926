import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Login from './pages/Auth/Login';
import Dashboard from './pages/Dashboard/Dashboard/Dashboard';
import DashboardContent from './pages/Dashboard/DashboardContent/DashboardContent';
import Home from './pages/Home/Home';
import NotFound from './pages/NotFound/NotFound';
import RequiredAuth from './pages/Shared/RequiredAuth/RequiredAuth';
import Categories from './pages/Dashboard/Categories/Categories';
import Users from './pages/Dashboard/Users/Users';
import AddUser from './pages/Dashboard/Users/AddUser';
import EditUser from './pages/Dashboard/Users/EditUser';
import AddCategory from './pages/Dashboard/Categories/AddCategory';
import EditCategory from './pages/Dashboard/Categories/EditCategory';
import Products from './pages/Dashboard/Products/Products';
import AddProduct from './pages/Dashboard/Products/AddProduct';
import EditProduct from './pages/Dashboard/Products/EditProduct';
import Roles from './pages/Dashboard/Roles/Roles';
import AddRole from './pages/Dashboard/Roles/AddRole';
import EditRole from './pages/Dashboard/Roles/EditRole';
import Checkout from './pages/Dashboard/Checkout/Checkout';
import ViewUser from './pages/Dashboard/Users/ViewUser';
import Stocks from './pages/Dashboard/Stocks/Stocks';
import Orders from './pages/Dashboard/Orders/Orders';
import ViewOrder from './pages/Dashboard/Orders/ViewOrder';
import './App.css';

// permissions
import RequiredCategoryList from './pages/Dashboard/Shared/Permissions/RequiredCategoryList';
import RequiredProductList from './pages/Dashboard/Shared/Permissions/RequiredProductList';
import RequiredRoleList from './pages/Dashboard/Shared/Permissions/RequiredRoleList';
import RequiredCartList from './pages/Dashboard/Shared/Permissions/RequiredCartList';
import RequiredUserList from './pages/Dashboard/Shared/Permissions/RequiredUserList';
import Return from './pages/Dashboard/Return/Return';
import AddReturn from './pages/Dashboard/Return/AddReturn';
import Account from './pages/Dashboard/Users/Account';
import About from './pages/About/About';
import RequiredOrderList from './pages/Dashboard/Shared/Permissions/RequiredOrderList';
import Suppliers from './pages/Dashboard/Suppliers/Suppliers';
import AddOrder from './pages/Dashboard/Suppliers/AddOrder';
import SupAccount from './pages/Dashboard/Suppliers/SupAccount';
import SupplierOrders from './pages/Dashboard/Suppliers/SupplierOrders';
import SupOrderView from './pages/Dashboard/Suppliers/SupOrderView';


function App() {
  return (
    <div className="">
      <Toaster position="top-center" reverseOrder={false} toastOptions={{ duration: 2000 }} />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/login" element={<Login />} />


        {/* dashboard routes */}
        <Route path="/dashboard" element={<RequiredAuth> <Dashboard /> </RequiredAuth>}>

          <Route index element={<RequiredAuth> <DashboardContent /> </RequiredAuth>}></Route>

          {/* category list permission */}
          <Route path='categories' element={<RequiredAuth>
            <RequiredCategoryList> <Categories /> </RequiredCategoryList>
          </RequiredAuth>}></Route>
          <Route path='add-category' element={<RequiredAuth>
            <RequiredCategoryList> <AddCategory /> </RequiredCategoryList>
          </RequiredAuth>}></Route>
          <Route path='edit-category/:categoryId' element={<RequiredAuth>
            <RequiredCategoryList> <EditCategory /> </RequiredCategoryList>
          </RequiredAuth>}></Route>

          {/* product list permission */}
          <Route path='products' element={<RequiredAuth>
            <RequiredProductList> <Products /> </RequiredProductList>
          </RequiredAuth>}></Route>
          <Route path='add-product' element={<RequiredAuth>
            <RequiredProductList> <AddProduct /> </RequiredProductList>
          </RequiredAuth>}></Route>
          <Route path='edit-product/:productId' element={<RequiredAuth>
            <RequiredProductList> <EditProduct /> </RequiredProductList>
          </RequiredAuth>}></Route>

          {/* product list permission */}
          <Route path='stocks' element={<RequiredAuth>
            <RequiredProductList> <Stocks /> </RequiredProductList>
          </RequiredAuth>}></Route>

          {/* product list permission */}
          <Route path='returns' element={<RequiredAuth>
            <RequiredProductList> <Return /> </RequiredProductList>
          </RequiredAuth>}></Route>
          <Route path='add-return' element={<RequiredAuth>
            <RequiredProductList> <AddReturn /> </RequiredProductList>
          </RequiredAuth>}></Route>

          {/* users */}
          <Route path='users' element={<RequiredAuth>
            <RequiredUserList>  <Users />  </RequiredUserList>
          </RequiredAuth>}></Route>
          <Route path='add-user' element={<RequiredAuth>
            <RequiredUserList> <AddUser /> </RequiredUserList>
          </RequiredAuth>}></Route>
          <Route path='edit-user/:userId' element={<RequiredAuth>
            <RequiredUserList> <EditUser /> </RequiredUserList>
          </RequiredAuth>}></Route>
          <Route path='view-user/:userId' element={<RequiredAuth>
            <RequiredUserList> <ViewUser /> </RequiredUserList>
          </RequiredAuth>}></Route>
          <Route path='user/account/:userId' element={<RequiredAuth>
            <RequiredUserList> <Account /> </RequiredUserList>
          </RequiredAuth>}></Route>

          {/* suppliers */}
          <Route path='suppliers' element={<RequiredAuth>
            <RequiredUserList> <Suppliers /> </RequiredUserList>
          </RequiredAuth>}></Route>
          <Route path='supplier/orders' element={<RequiredAuth>
            <RequiredUserList> <SupplierOrders /> </RequiredUserList>
          </RequiredAuth>}></Route>
          <Route path='suppliers/add-order' element={<RequiredAuth>
            <RequiredUserList> <AddOrder /> </RequiredUserList>
          </RequiredAuth>}></Route>
          <Route path='supplier/account/:userId' element={<RequiredAuth>
            <RequiredUserList> <SupAccount /> </RequiredUserList>
          </RequiredAuth>}></Route>
          <Route path='supplier/order/:orderId' element={<RequiredAuth>
            <RequiredOrderList> <SupOrderView /> </RequiredOrderList>
          </RequiredAuth>}></Route>


          {/* role list permission */}
          <Route path='roles' element={<RequiredAuth>
            <RequiredRoleList> <Roles /> </RequiredRoleList>
          </RequiredAuth>}></Route>
          <Route path='add-role' element={<RequiredAuth>
            <RequiredRoleList> <AddRole /> </RequiredRoleList>
          </RequiredAuth>}></Route>
          <Route path='edit-role/:roleId' element={<RequiredAuth>
            <RequiredRoleList> <EditRole /> </RequiredRoleList>
          </RequiredAuth>}></Route>

          {/* orders */}
          <Route path='orders' element={<RequiredAuth>
            <RequiredOrderList> <Orders /> </RequiredOrderList>
          </RequiredAuth>}></Route>
          <Route path='order/view/:orderId' element={<RequiredAuth>
            <RequiredOrderList> <ViewOrder /> </RequiredOrderList>
          </RequiredAuth>}></Route>

          {/* checkout */}
          <Route path='checkout' element={<RequiredAuth>
            <RequiredCartList> <Checkout /> </RequiredCartList>
          </RequiredAuth>}></Route>

        </Route>
        {/* dashboard routes */}


        <Route path="*" element={<NotFound />} />


      </Routes>
    </div>
  );
}

export default App;
