import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoTrash, IoCogOutline } from "react-icons/io5";
import Loading from '../../Shared/Loading/Loading';
import useRoles from '../../../hooks/useRoles';
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../../Utilities/Breadcrumb';
import useDelete from '../../../hooks/useDelete';

const Roles = () => {

    const navigate = useNavigate();
    const { sendDeleteRequest } = useDelete();
    const [loading, setLoading] = useState(false);

    // get all roles
    const { roles, rolesLoading, rolesFetch } = useRoles();

    // edit role
    const handleEditRole = (roleId) => {
        navigate(`/dashboard/edit-role/${roleId}`)
    }

    // delete role without admin
    const handleDeleteRole = (roleId) => {
        sendDeleteRequest(`${process.env.REACT_APP_SERVER_URL}role/${roleId}`, setLoading, rolesFetch);
    }

    // datas
    const columns = [
        //{
        //     id: row => row.index,
        //     name: 'SL',
        //     cell: (row, index) => index + 1,
        // },
        {
            name: 'Name',
            selector: row => row.name,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Permissions',
            selector: row => row.permissionsId.map((perm, index) =>
                <div key={index} className='role-list bg-info py-1 px-3 text-white m-1 rounded-pill' >{perm}
                </div>),
            filterable: true,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => (
                <>
                    <button className='btn btn-warning me-2 text-white' onClick={() => handleEditRole(row._id)}><IoCogOutline /></button>
                    <button className='btn btn-danger' disabled={row.name.toLowerCase() === 'admin'} onClick={() => handleDeleteRole(row._id)}><IoTrash /></button>
                </>
            ),
        },
    ];

    // loading
    if (rolesLoading || loading) { return <Loading getHeight={'80vh'} /> }

    return (
        <div>

            {/* ================== breadcrumb ==================== */}
            <Breadcrumb
                indexTitle={'Roles'}
                indexLink={'/dashboard/roles'}
                adressLink={'Add New Role'}
                address={'/dashboard/add-role'}
            />
            {/* ================================================== */}


            {/* main content */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card px-3 user-table">

                        <DataTable
                            columns={columns}
                            data={roles.roles}
                            highlightOnHover
                            progressPending={rolesLoading}
                            pagination
                            subHeader
                        />

                    </div>
                </div>
            </div>
            {/* main content */}
        </div>
    );
};

export default Roles;