import React from 'react';
import useAuth from '../../../../hooks/useAuth';
import PermissionDenied from '../PermissionDenied/PermissionDenied';

const RequiredCategoryList = ({ children }) => {
    const { categoryList } = useAuth();
  
      if(!categoryList) { 
          return <PermissionDenied /> 
      }
      
      return children;
};

export default RequiredCategoryList;