import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineEye } from "react-icons/ai";
import { IoTrash, IoCogOutline } from "react-icons/io5";
import { MdAccountBox } from "react-icons/md";
import DataTable from 'react-data-table-component';
import Loading from '../../Shared/Loading/Loading';
import useUsers from '../../../hooks/useUsers';
import toast from 'react-hot-toast';
import swal from 'sweetalert';
import Breadcrumb from '../../../Utilities/Breadcrumb';
// import userDemo from '../../../assets/images/user-demo.png';
import { useEffect } from 'react';
import SearchFormLive from '../../../Utilities/SearchFormLive';
import useRoles from '../../../hooks/useRoles';


const Users = () => {

    // global
    const supplier = 'Supplier';
    const componentRef = useRef();
    const navigate = useNavigate();

    // states
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [selectedRole, setSelcetedRole] = useState({ label: "Select Role", value: "" })

    // users/roles info
    const { users, usersLoading, usersFetch } = useUsers('');
    const { roles, rolesLoading } = useRoles();

    const roles_name = roles?.roles?.filter((item) => item.name.toLowerCase() !== supplier.toLowerCase()).map((e) => {
        return { label: `${e.name}`, value: e.name }
    }) || []

    useEffect(() => {
        setOptions([{ label: "Select Role", value: "" }, ...roles_name])
    }, [users?.users])

    // filter data by search text
    useEffect(() => {
        if (searchText && selectedRole.value) {
            // get data by role
            const filterByRole = users?.users?.filter((el) => el?.role.name === selectedRole.value);

            // filter data
            setFilterData(filterByRole?.filter((el) =>
                (el.name)?.match(new RegExp(searchText, "i")) || (el.email)?.match(new RegExp(searchText, "i")) ||
                (el.mobile)?.match(new RegExp(searchText, "i")) || (el.address)?.match(new RegExp(searchText, "i"))
            ));
        } else if (searchText && !selectedRole.value) {

            // get data by role
            const filterWithourSup = users?.users?.filter((el) => el?.role.name.toLowerCase() !== supplier.toLowerCase());

            setFilterData(filterWithourSup.filter((el) =>
                (el.name)?.match(new RegExp(searchText, "i")) || (el.email)?.match(new RegExp(searchText, "i")) ||
                (el.mobile)?.match(new RegExp(searchText, "i")) || (el.address)?.match(new RegExp(searchText, "i"))
            ));
        } else if (selectedRole.value && !searchText) {
            const filterByRole = users?.users?.filter((el) => el?.role.name === selectedRole.value);
            setFilterData(filterByRole);
        } else {
            setFilterData(users?.users.filter((item) => item.role?.name.toLowerCase() !== supplier.toLowerCase()));
        }
    }, [searchText, users?.users, selectedRole.value]);

    // delete user
    const handleDeleteUser = async (userId) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this imaginary file!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                setLoading(true);
                const deleteUserConfirm = async () => {
                    fetch(`${process.env.REACT_APP_SERVER_URL}user/${userId}`, {
                        method: 'DELETE',
                        headers: {
                            authorization: `Bearer ${localStorage.getItem('accessToken')}`
                        }
                    })
                        .then(res => {
                            if (res.status === 401 || res.status === 403) {
                                localStorage.removeItem('accessToken');
                                localStorage.removeItem('userId');
                                navigate('/login');
                                toast.error('Forbidden/Unauthorized access!');
                            }

                            return res.json();
                        })
                        .then(data => {
                            setLoading(false);
                            if (data.message && data.success === true) {
                                usersFetch();
                                if (localStorage.getItem('userId') === userId) {
                                    localStorage.removeItem('userId');
                                }
                                toast.success(data.message);
                            }
                            if (data.message && data.success === false) {
                                toast.error(data.message);
                            }
                        })
                }
                deleteUserConfirm();
            }
        });
    }

    // datas
    const columns = [
        // {
        //     name: 'Avatar',
        //     selector: row => row.img ? <img
        //         width={50} height={50} src={`${process.env.REACT_APP_SERVER_URL}${row.img}`} alt={row.name}
        //         className='my-2 rounded-pill border border-warning oject-cover'
        //     /> : <img
        //         width={50} height={50} src={`${userDemo}`} alt='DemoImage'
        //         className='my-2 rounded-pill border border-warning oject-cover'
        //     />,
        // },
        {
            name: 'Name',
            selector: row => row.name,
            filterable: true,
            sortable: true,
            maxWidth: '250px'
        },
        {
            name: 'Email',
            selector: row => row.email,
            filterable: true,
            maxWidth: '220px'
        },
        // {
        //     name: 'Balance',
        //     selector: row => <span className={`${row.accountId.balance > 0 ? 'text-success' : 'text-danger'} p-2 rounded-pill`}>{row.accountId.balance} ৳</span>,
        //     filterable: true,
        //     center: true,

        // },
        {
            name: 'Role',
            selector: row => <span className={`${row.role?.name ? 'bg-info' : 'bg-danger'} p-2 text-white rounded-pill text-capitalize`}>{row.role?.name ? row.role?.name : 'None'}</span>,
            // maxWidth: '200px'
        },
        {
            name: 'Action',
            cell: row => (
                <>
                    {/* <Link to={`/dashboard/user/account/${row._id}`} className='btn account-btn me-2'><MdAccountBox /></Link>
                    <Link to={`/dashboard/view-user/${row._id}`} className='btn view-btn me-2'><AiOutlineEye /></Link> */}
                    <Link to={`/dashboard/edit-user/${row._id}`} className='btn btn-warning text-white me-2'><IoCogOutline /></Link>
                    <button className='btn btn-danger' disabled={row.email === 'admin@gmail.com'} onClick={() => handleDeleteUser(row._id)}><IoTrash /></button>
                </>
            ),
        },
    ];

    // loading
    if (usersLoading || loading || rolesLoading) { return <Loading getHeight={'80vh'} /> }

    return (
        <div>

            {/* ================== breadcrumb ==================== */}
            <Breadcrumb
                indexTitle={'Users'}
                indexLink={'/dashboard/users'}
                adressLink={'Add New User'}
                address={'/dashboard/add-user'}
            // printBtn={true}
            // componentRef={componentRef}
            />
            {/* ================================================== */}


            {/* ================== main ==================== */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card py-4 px-3" >

                        <div className='d-flex justify-content-end'>
                            <SearchFormLive
                                placeholder={`Search by customer name`}
                                handleChange={(e) => setSearchText(e.target.value)}
                                selectNeed={true}
                                options={options}
                                defaultValue={selectedRole}
                                setSelectedOption={setSelcetedRole}
                            // hanldeChange={(e) => setSelcetedRole(option)}
                            />
                        </div>

                        <div className='users_datatable' ref={componentRef}>
                            <DataTable
                                columns={columns}
                                data={filterData}
                                highlightOnHover
                                progressPending={usersLoading}
                                pagination
                                persistTableHead={true}
                            />
                        </div>


                    </div>
                </div>
            </div>
            {/* ================================================== */}
        </div>
    );
};

export default Users;