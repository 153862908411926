import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const useAuth = () => {
    let navigate = useNavigate();
    const [loggedUser, setLoggedUser] = useState({});
    const [logged, setLogged] = useState(true);
    const [loggedUserLoading, setLoggedUserLoading] = useState(true);
    const userId = localStorage.getItem('userId');

    // permissions
    const [productList, setProductList] = useState(true);
    const [categoryList, setCategoryList] = useState(true);
    const [userList, setUserList] = useState(true); 
    const [roleList, setRoleList] = useState(true);
    const [cartList, setCartList] = useState(true);
    const [orderList, setOrderList] = useState(true);

    useEffect( () => {
        if(userId){
            setLoggedUserLoading(true);

            fetch(`${process.env.REACT_APP_SERVER_URL}profile/${userId}`, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    authorization: `Bearer ${localStorage.getItem('accessToken')}`
                },
            })
            .then(res => {
                if(res.status === 401 || res.status === 403){
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('userId');
                    navigate('/login');
                    toast.error('Forbidden/Unauthorized access!', { duration: 2000 });
                }
                return res.json();
            })
            .then(data => {
                setLoggedUserLoading(false);
               
                if(data.success === true){
                    setLoggedUser(data.user);
                    setLogged(true)
    
                    // set permissions
                    setProductList(data.permissions.productList)
                    setCategoryList(data.permissions.categoryList)
                    setUserList(data.permissions.userList)
                    setRoleList(data.permissions.roleList)
                    setCartList(data.permissions.cartList)
                    setOrderList(data.permissions.orderList)
                }

                if(data.success === false){
                    setLoggedUser({});
                    setLogged(false)
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('userId');
                    navigate('/login');
                }
            })
        }else{
            setLoggedUser({});
            setLogged(false)
            navigate('/login');
        }
    }, [navigate, userId])

    return { 
        loggedUser, logged, loggedUserLoading, 
        productList, categoryList, userList, roleList, cartList, orderList 
    };
}

export default useAuth;