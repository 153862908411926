import React from 'react';
import useAuth from '../../../../hooks/useAuth';
import PermissionDenied from '../PermissionDenied/PermissionDenied';

const RequiredRoleList = ({ children }) => {

    const { roleList } = useAuth();
  
    if(!roleList) { 
        return <PermissionDenied /> 
    }
    
    return children;
};

export default RequiredRoleList;