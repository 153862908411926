import React from 'react';
import './inputStyle.css'

const Select = ({
    label, inputId, inputValue, isRequired, onChangeData, options, dynamicOptions, errorValue, initialOptionTitle
}) => {
    return (
        <div className="col-md-6">

            <label htmlFor={inputId} className="form-label">
                {label}
                {isRequired && <span className='text-danger'>*</span>}
            </label>

            <select
                className="form-select"
                id={inputId}
                value={inputValue}
                required={isRequired}
                onChange={onChangeData}>

                {initialOptionTitle && <option value=''>{initialOptionTitle}</option>}

                {options.map((item, index) => <option
                    key={index}
                    value={item._id ? item._id : item.value}
                >
                    {item.name ? item.name : item.label}
                </option>)
                }

            </select>
            {
                errorValue && <p className='m-0 mt-1 text-danger'> {errorValue} </p>
            }
        </div>
    );
};

export default Select;