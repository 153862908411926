import React from 'react';
import { Link } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { printPageLandscapeStyle } from '../hooks/helpers';
import Button from './Button';

const Breadcrumb = ({
    title,
    indexTitle,
    indexLink,
    createPageTitle,
    createPageLink,
    editPageTitle,
    editPageLink,
    btnTitle,
    adressLink,
    address,
    loading,
    printBtn,
    componentRef
}) => {
    return (
        <div className="page-header">
            <div className="card breadcrumb-card">
                <div className="row justify-content-center justify-content-md-between align-items-center">

                    <div className="col-12 col-md-6">
                        <h3 className="page-title">{indexTitle || 'Dashboard'}</h3>

                        <ul className="breadcrumb">
                            <li className={`breadcrumb-item ${(!indexTitle && !createPageTitle && !editPageTitle) && 'active-breadcrumb'}`}>
                                <Link to="/dashboard">Dashboard</Link>
                            </li>

                            {indexTitle && <li className={`breadcrumb-item ${(!createPageTitle && !editPageTitle) && 'active-breadcrumb'}`}>
                                <Link to={indexLink}>{indexTitle}</Link>
                            </li>}

                            {createPageTitle && <li className="breadcrumb-item active-breadcrumb">
                                <Link to={createPageLink}>{createPageTitle}</Link>
                            </li>}

                            {editPageTitle && <li className="breadcrumb-item active-breadcrumb">
                                <Link to={editPageLink}>{editPageTitle}</Link>
                            </li>}
                        </ul>

                    </div>

                    <div className="col-12 col-xl-3 col-md-4">
                        <div className='text-center text-md-end mt-3 mt-md-0 me-2'>

                            {printBtn ?
                                <ReactToPrint
                                    trigger={() => <button type='button' className='btn print-btn text-end me-2'>Print this out!</button>}
                                    content={() => componentRef.current}
                                    pageStyle={printPageLandscapeStyle}
                                />
                                : null}


                            {btnTitle && <Button title={btnTitle} loading={loading} />}
                            {adressLink && <Link to={address} className='btn primary-btn'>{adressLink}</Link>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Breadcrumb;