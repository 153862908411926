import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../../Shared/Loading/Loading';
import Select from 'react-select'
import useCart from '../../../hooks/useCart';
import toast from 'react-hot-toast';
import useUsers from '../../../hooks/useUsers';
import Breadcrumb from '../../../Utilities/Breadcrumb';
import Input from '../../../Utilities/Input';
import Print from './Print';
import { selectStyle } from '../../../hooks/helpers';

const Checkout = () => {

    const supplier = 'Supplier';
    const today = new Date();
    const componentRef = useRef();
    const navigate = useNavigate();

    // cart data
    const { cartItems, subTotal, cartLoading } = useCart();
    // get users
    const { users, usersLoading } = useUsers('');

    const [loading, setLoading] = useState(false);
    const [filterUsers, setFilterUsers] = useState([]);
    const [orderConfirm, setOrderConfirm] = useState({
        invoiceId: 'TME' + today.valueOf(),
        userId: '', userEmail: '', userName: '',
        laborCharge: 0, discount: 0, cash: 0, due: 0, createdAt: today.toString()
    })
    const [total, setTotal] = useState(0);

    // select label
    const users_name = users?.users?.filter((el) => el?.role.name.toLowerCase() !== supplier.toLowerCase()).map((e) => {
        return { label: `${e.name}`, value: e._id, email: e.email, name:e.name }
    }) || []

    useEffect(() => {
        setFilterUsers([{ label: "Select Customer", value: "", email: '' }, ...users_name])
    }, [users?.users])

    useEffect(() => {
        setTotal((subTotal + orderConfirm.laborCharge) - orderConfirm.discount)
    }, [orderConfirm.laborCharge, subTotal, orderConfirm.discount])

    useEffect(() => {
        setOrderConfirm({ ...orderConfirm, due: ((subTotal + orderConfirm.laborCharge) - orderConfirm.discount) - orderConfirm.cash })
    }, [subTotal, orderConfirm.laborCharge, orderConfirm.discount, orderConfirm.cash])

    // select user to get value
    const hanldeChange = (newValue) => {
        setOrderConfirm({ ...orderConfirm, userId: newValue.value, userEmail: newValue.email, userName: newValue.name })
    }

    // order 
    const handleOrder = (e) => {
        e.preventDefault();

        if (orderConfirm.userId === '' || orderConfirm.userId === undefined) {
            toast.error('Select a user to order!');
        } else if (orderConfirm.cash < 0) {
            toast.error('Cash value can not be less than 0!');
        } else if (orderConfirm.cash > total) {
            toast.error(`Cash value can't be greater than ${total}!`);
        } else {
            setLoading(true)

            const totalCharge = (subTotal + orderConfirm.laborCharge) - orderConfirm.discount;
            const data = { ...orderConfirm, subTotal: subTotal, total: totalCharge, productData: cartItems.cartItems }

            console.log(data)

            fetch(`${process.env.REACT_APP_SERVER_URL}order/create`, {
                method: 'POST',
                headers: {
                    'content-Type': 'application/json',
                    authorization: `Bearer ${localStorage.getItem('accessToken')}`
                },
                body: JSON.stringify(data)
            })
                .then(res => {
                    if (res.status === 401 || res.status === 403) {
                        localStorage.removeItem('accessToken');
                        localStorage.removeItem('userId');
                        navigate('/login');
                        toast.error('Forbidden/Unauthorized access!');
                    }
                    return res.json();
                })
                .then(data => {
                    setLoading(false);
                    if (data.message && data.success === true) {
                        navigate('/dashboard/orders');
                        toast.success(data.message);
                    }
                    if (data.message && data.success === false) {
                        toast.error(data.message);
                    }
                })
        }
    }

    // loding data
    if (cartLoading || usersLoading) { return <Loading getHeight={'80vh'} /> }

    return (
        <form onSubmit={handleOrder} className='position-relative'>

            {/* ================== breadcrumb ==================== */}
            <Breadcrumb
                indexTitle={'Checkout'}
                indexLink={'/dashboard/checkout'}
                btnTitle={'Confirm Order'}
                loading={loading}
            />
            {/* ================================================== */}

            <div className='position-fixed' style={{ top: '50%', right: '0', zIndex: '999' }}>
                <span className='btn btn-primary' style={{ borderRadiusTopRight: '0px' }}>Total: {total} ৳</span>
            </div>



            {/* checkout for details */}
            <div className="row mb-5">
                <div className="col-md-12">
                    <div className="card py-4 px-3">

                        <div className="row gy-4">

                            <div className="col-sm-6">
                                <label htmlFor="userId" className="form-label">Customer Id<span className='text-danger'>*</span></label>

                                <Select
                                    defaultValue={filterUsers[0]}
                                    options={filterUsers}
                                    getOptionLabel={(option) => `${option.label} ${option.email && ':'} ${option.email}`}
                                    onChange={hanldeChange}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    styles={selectStyle}
                                    required
                                />
                            </div>

                            <Input
                                label={'Labor charge'} type={'number'} inputId={'laborCharge'}
                                placeholder={'Enter labor charge'} isRequired={true}
                                inputValue={orderConfirm?.laborCharge}
                                onChangeData={(e) => setOrderConfirm({ ...orderConfirm, laborCharge: e.target.value && parseFloat(e.target.value) })}
                            />

                            <Input
                                label={'Discount'} type={'number'} inputId={'discount'}
                                placeholder={'Enter discount amount'} isRequired={true}
                                inputValue={orderConfirm?.discount}
                                onChangeData={(e) => setOrderConfirm({ ...orderConfirm, discount: e.target.value && parseFloat(e.target.value) })}
                            />

                            <Input
                                label={'Cash Amount'} type={'number'} inputId={'cash'}
                                placeholder={'Enter cash amount'} isRequired={true}
                                inputValue={orderConfirm?.cash}
                                onChangeData={(e) => setOrderConfirm({ ...orderConfirm, cash: e.target.value && parseFloat(e.target.value) })}
                            />

                            <Input
                                label={'Due Amount'} type={'number'} inputId={'due'} isDisabled={true}
                                placeholder={'Due Amount'} isRequired={true}
                                inputValue={orderConfirm?.due}
                            />

                        </div> {/* data row end */}

                    </div>
                </div>
            </div>
            {/* checkout for details */}


            {/* print content */}
            {orderConfirm?.userId && <Print
                componentRef={componentRef}
                orderConfirm={orderConfirm}
                cartItems={cartItems}
                subTotal={subTotal}
                total={total}
            />}
            {/* print content */}
        </form>
    );
};

export default Checkout;