import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { RiLockPasswordLine } from "react-icons/ri";
import toast from 'react-hot-toast';
import Loading from '../Shared/Loading/Loading';
import './login.css';
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";

const Login = () => {

    const navigate = useNavigate();
    const [loginData, setLoginData] = useState({ email: 'soumik@gmail.com', password: 'abcabc' });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const [passVisible, setPassVisible] = useState(false);

    // if user logged in then no access to login page
    const userId = localStorage.getItem('userId');
    useEffect(() => {
        if (userId) { navigate('/dashboard') }
    }, [userId, navigate])

    // login api hit
    const fetchLoginApi = async () => {
        setLoading(true);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(loginData)
        };

        fetch(`${process.env.REACT_APP_SERVER_URL}login`, requestOptions)
            .then(res => res.json())
            .then(data => {
                setLoading(false);
                if (data.success === true && data.accessToken) {
                    toast.success(`${data.message}`);
                    localStorage.setItem('accessToken', data.accessToken);
                    localStorage.setItem('userId', data.userId);
                    navigate('/dashboard');
                }
                if (data.success === false) {
                    toast.error(`${data.message}`);
                }
            });
    }

    // login 
    const handleLogin = (e) => {
        e.preventDefault();
        const { email, password } = loginData;

        // error checking
        if (email === '' && password === '') {
            setError('Email and password filed required')
        } else if (email === '') {
            setError('Email filed is required')
        } else if (password === '') {
            setError('Password filed is required')
        } else {
            setError('');
            fetchLoginApi();
        }
    }

    if (loading) { return <Loading /> }

    return (
        <>
            <section id="loginpage">
                <Container>
                    <div className="h-100vh d-flex justify-content-center align-items-center">
                        <div className="col-xl-4 col-lg-6 col-md-8 col-10 login-container my-5">
                            <div className="d-flex justify-content-center ">
                                <div className="icon-stacked d-flex justify-content-center align-items-center">
                                    <div className="icon"><RiLockPasswordLine /></div>
                                </div>
                            </div>

                            <div className="login-form">
                                <div className="py-3 text-center">
                                    <h5 className='primary-link' >Login To Inventory Management</h5>
                                </div>

                                {
                                    error && <p className='text-danger text-center mb-3'>{error}</p>
                                }

                                <form onSubmit={handleLogin}>
                                    <div className="form-group">
                                        <input type="email" className="form-control neu-input"
                                            value={loginData?.email}
                                            onChange={(e) => setLoginData({ ...loginData, email: e.target.value })} placeholder="Your email" autoComplete="on"
                                        />
                                    </div>
                                    <div className="form-group mt-3">
                                        <div className="password-eye">
                                            <input type={passVisible ? "text" : "password"} className="form-control neu-input"
                                                value={loginData?.password}
                                                onChange={(e) => setLoginData({ ...loginData, password: e.target.value })} placeholder="Password"
                                            />
                                            <div onClick={() => setPassVisible(!passVisible)} className="eye cs_primary" type="button">
                                                {passVisible ? <IoEyeOutline /> : <IoEyeOffOutline />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mt-4">
                                        <button type="submit" className="btn btn-primary  btn-lg btn-block w-100 neu-button">Login</button>
                                    </div>
                                    <div className="form-group forget-password text-center my-3">
                                        Forgot Password? <a className='primary-link' href="#s"> Reset Now</a>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default Login;