import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../../Shared/Loading/Loading'
import useRoles from '../../../hooks/useRoles';
import toast from 'react-hot-toast';
import axios from 'axios'
import Breadcrumb from '../../../Utilities/Breadcrumb';
import Input from '../../../Utilities/Input';
import Select from '../../../Utilities/Select';

const AddUser = () => {

    const supplier = 'Supplier';

    const [userData, setUserData] = useState({
        name: '', img: '', email: '', mobile: '', address: '', password: '', confirmPassword: '', role: '', status: 'active'
    });
    const [errors, setError] = useState({
        name: '', img: '', email: '', mobile: '', address: '', password: '', confirmPassword: '', role: ''
    });
    const [loading, setLaoding] = useState(false);
    const [imgSrc, setImgSrc] = useState('');
    const navigate = useNavigate();


    // get all roles
    const { roles, rolesLoading } = useRoles();

    const roles_name = roles?.roles?.map((e) => {
        return { label: `${e.name}`, value: e._id }
    }) || []

    // image validation
    const handleImageChange = e => {
        if (!e.target.files[0].name.match(/\.(jpg|jpeg|png|JPG|PNG)$/)) {
            setImgSrc('');
            setUserData({ ...userData, img: '' })
            setError({ ...errors, img: 'Only jpg, jpeg & png file supported' });
        } else {
            setError({ ...errors, img: '' });
            setImgSrc(URL.createObjectURL(e.target.files[0]));
            setUserData({ ...userData, img: e.target.files[0] })
        }
    }

    // add new user
    const handleAddUser = async (e) => {
        e.preventDefault();

        if (userData.password.length < 6) {
            toast.error('Minimum six digit password!');
        } else if (userData.password !== userData.confirmPassword) {
            toast.error('Password did not matched!');
        } else {
            setLaoding(true);

            var formData = new FormData();
            formData.append('name', userData.name);
            formData.append('img', userData.img);
            formData.append('email', userData.email);
            formData.append('mobile', userData.mobile);
            formData.append('address', userData.address);
            formData.append('password', userData.password);
            formData.append('role', userData.role);
            formData.append('status', userData.status);

            const config = {
                headers: {
                    authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            };

            axios.post(`${process.env.REACT_APP_SERVER_URL}user/create`, formData, config)
                .then(res => {
                    if (res.status === 401 || res.status === 403) {
                        localStorage.removeItem('accessToken');
                        localStorage.removeItem('userId');
                        navigate('/login');
                        toast.error('Forbidden/Unauthorized access!');
                    }

                    if (res.status === 500 || res.data.success === false) {
                        toast.error('Server error!');
                    }

                    if (res.status === 200 || res.data.success === true) {
                        setUserData({ name: '', img: '', email: '', mobile: '', address: '', password: '', confirmPassword: '', role: '', status: 'active' });
                        setImgSrc('');
                        toast.success(res.data.message);
                    }

                    setLaoding(false);
                })
        }
    }

    if (rolesLoading) { return <Loading /> }

    return (
        <form encType='multipart/form-data' onSubmit={handleAddUser}>

            {/* ================== breadcrumb ==================== */}
            <Breadcrumb
                indexTitle={'Users'}
                indexLink={'/dashboard/users'}
                createPageTitle={'Add User'}
                createPageLink={'/dashboard/add-user'}
                btnTitle={'Add User'}
                loading={loading}
            />
            {/* ================================================== */}


            {/* main content */}
            <div className="row mb-4">
                <div className="col-md-12">
                    <div className="card py-4 px-3">

                        <h4>Add New User</h4>
                        <hr />


                        <div className="row gx-5 gy-4">

                            <div className="col-sm-12">
                                <div className='row justify-content-center'>
                                    <div className="col-md-6 mt-2" >
                                        {
                                            imgSrc && <div className='text-center add-img mb-2'>
                                                <img width={100} height={100} src={imgSrc} alt="" />
                                            </div>
                                        }
                                        <div class="box text-center">
                                            <input type="file" name="img" id="img" className="inputfile inputfile-1" onChange={handleImageChange} />
                                            <label htmlFor="img"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17"><path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" /></svg> <span>Choose a file&hellip;</span></label>
                                        </div>
                                        {
                                            errors.img && <p className='text-danger m-0'> {errors.img} </p>
                                        }
                                    </div>
                                </div>
                            </div>

                            <Input
                                label={'User name'} type={'text'} inputId={'name'}
                                placeholder={'Enter user name'} isRequired={true}
                                inputValue={userData?.name} errorValue={errors.name}
                                onChangeData={(e) => setUserData({ ...userData, name: e.target.value })}
                            />

                            <Input
                                label={'User email'} type={'email'} inputId={'email'}
                                placeholder={'Enter user email'} isRequired={true}
                                inputValue={userData?.email} errorValue={errors.email}
                                onChangeData={(e) => setUserData({ ...userData, email: e.target.value })}
                            />

                            <Input
                                label={'User mobile'} type={'text'} inputId={'mobile'}
                                placeholder={'Enter user mobile'} isRequired={false}
                                inputValue={userData?.mobile} errorValue={errors.mobile}
                                onChangeData={(e) => setUserData({ ...userData, mobile: e.target.value })}
                            />

                            <Input
                                label={'User address'} type={'text'} inputId={'address'}
                                placeholder={'Enter user address'} isRequired={false}
                                inputValue={userData?.address} errorValue={errors.address}
                                onChangeData={(e) => setUserData({ ...userData, address: e.target.value })}
                            />

                            <Input
                                label={'Password'} type={'password'} inputId={'password'}
                                placeholder={'Enter password'} isRequired={true}
                                inputValue={userData?.password} errorValue={errors.password}
                                onChangeData={(e) => setUserData({ ...userData, password: e.target.value })}
                            />

                            <Input
                                label={'Confirm Password'} type={'password'} inputId={'confirmPassword'}
                                placeholder={'Enter confirm password'} isRequired={true}
                                inputValue={userData?.confirmPassword} errorValue={errors.confirmPassword}
                                onChangeData={(e) => setUserData({ ...userData, confirmPassword: e.target.value })}
                            />

                            <Select
                                label={'Role'} isRequired={true} inputId={'role'}
                                inputValue={userData.role} errorValue={errors.role}
                                onChangeData={(e) => setUserData({ ...userData, role: e.target.value })}
                                initialOptionTitle={'Select Role'}
                                options={roles_name}
                            />

                        </div>
                    </div>
                </div>
            </div>
            {/* main content */}
        </form>
    );
};

export default AddUser;