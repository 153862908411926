import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import Select from 'react-select'
import { selectStyle } from '../hooks/helpers';

const componentName = ({
    placeholder,
    handleChange,
    selectNeed,
    defaultValue,
    options,
    setSelectedOption,
}) => {
    return (
        <div className='d-flex align-items-center mb-3'>
            {selectNeed ? <div className='me-4' style={{ width: '380px' }}>
                <Select
                    defaultValue={defaultValue}
                    options={options}
                    getOptionLabel={(option) => `${option.label}`}
                    onChange={(option) => {
                        setSelectedOption(option);
                    }}
                    className="basic-single"
                    classNamePrefix="select"
                    styles={selectStyle}
                    required
                />
            </div> : null}

            <InputGroup className='searchLive'>
                <Form.Control
                    placeholder={placeholder}
                    type='text'
                    id='search'
                    onChange={handleChange}
                />
            </InputGroup>
        </div>
    );
};

export default componentName;