import React from 'react';
import fabLogo from '../../../assets/icon.png';
import { IoLocationSharp, IoCallSharp, IoMail } from "react-icons/io5";
import { Table } from 'react-bootstrap';
import { shopAddress, shopCategory, shopNumber, shopTitle } from '../../../configs/constants';

const AccountPrint = ({ data, perPage }) => {

    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

    return (
        <div className='p-2'>
            <div className='print-container'>

                <div className="invoice-header p-5">
                    <div className="row">
                        <div className="col-6">

                            <div className="logo">
                                <div className="d-flex">
                                    <img src={fabLogo} width={50} height={50} alt="Logo" />
                                    <div className="name-title px-2">
                                        <h5 className='m-0'>{shopTitle}</h5>
                                        <p>{shopCategory}</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-6">
                            <h5 className="">INVOICE</h5>
                            <div className="company-inforamtion">

                                <p> <span> <IoLocationSharp /></span> {shopAddress}</p>
                                <p> <span> <IoCallSharp /></span>  {shopNumber}</p>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="invoice_details pt-4 pb-1">
                    <Table bordered>
                        <tbody>
                            <tr>
                                <td>
                                    <p>Statement to,</p>
                                    <strong>{data?.accountInfo?.userId?.name} </strong>
                                    <p> <IoMail /> {data?.accountInfo?.userId?.email}</p>
                                    <p> <IoLocationSharp /> {data?.accountInfo?.userId?.address}</p>
                                    <p> <IoCallSharp /> {data?.accountInfo?.userId?.mobile}</p>
                                </td>
                                <td>
                                    <p>Statement info,</p>
                                    <p>Date: {date}</p>
                                </td>
                                <td>
                                    <p>Balance</p>
                                    <h4>{data?.accountInfo?.balance} BDT</h4>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>

            </div>


            <div className='transaction-infos'>
                <hr className='m-0 mt-2' />

                <div className='transaction-header row justify-content-between text-center py-2'>
                    <div className='col-2'><p className='m-0 text-center font-14'>Date</p></div>
                    <div className='col-2'><p className='m-0 text-center font-14'>Time</p></div>
                    <div className='col-2'><p className='m-0 text-center font-14'>Cash</p></div>
                    <div className='col-2'><p className='m-0 text-center font-14'>Due</p></div>
                    <div className='col-2'><p className='m-0 text-center font-14'>Type</p></div>
                    <div className='col-2'><p className='m-0 text-center font-14'>Balance</p></div>
                </div>

                <hr className='m-0 mb-3' />

                {data?.account[0]?.transactions ?
                    data?.account[0]?.transactions.slice(0, perPage).map(item => <div className='transaction-item row justify-content-between mb-3' key={item._id}>
                        <div className='col-2'><p className='text-center font-14'>{item.createdAt.slice(0, 10)}</p></div>
                        <div className='col-2'><p className='text-center font-14'>{item.createdAt.slice(11, 16)}</p></div>
                        <div className='col-2'><p className='text-center font-14'>{item.cash} ৳</p></div>
                        <div className='col-2'><p className='text-center font-14'>{item.due} ৳</p></div>
                        <div className='col-2'><p className='text-center font-14'>{item.type}</p></div>
                        <div className='col-2'><p className='text-center font-14'>{item.balance} ৳</p></div>
                    </div>) : <p className='text-center mt-4 text-danger'>No records</p>
                }

                <div className='p-2'>
                    <hr className='csbg_primary' />
                    <p className='my-1 text-center font-12'>Thank you for choosing our products. We believe you will be satisfied by our service</p>
                    <p className='my-1 text-center font-12'>Software developed by Kala Tabij. Phone: +8801689201370, +8801713644570</p>
                </div>
            </div>
        </div>
    );
};

export default AccountPrint;