import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { AiOutlineEye } from "react-icons/ai";
import { IoTrash } from "react-icons/io5";
import DataTable from 'react-data-table-component';
import toast from 'react-hot-toast';
import Loading from '../../Shared/Loading/Loading';
import Breadcrumb from '../../../Utilities/Breadcrumb';
import useDelete from '../../../hooks/useDelete';
import SearchForm from '../../../Utilities/SearchForm';

const Orders = () => {

    const navigate = useNavigate();
    const { sendDeleteRequest } = useDelete();
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);

    // get all orders
    const { data: orders, isLoading, refetch } = useQuery(['orders', searchText], () =>
        fetch(`${process.env.REACT_APP_SERVER_URL}order/index?invoiceId=${searchText}`, {
            headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
            .then(res => {
                if (res.status === 401 || res.status === 403) {
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('userId');
                    navigate('/login');
                    toast.error('Forbidden/Unauthorized access!');
                }
                if (res.status === 500) {
                    toast.error('Forbidden/Unauthorized access!');
                }
                return res.json();
            }))

    // view single order
    const handleViewOrder = (orderId) => {
        navigate(`/dashboard/order/view/${orderId}`)
    }

    // set the searh to search query
    const handleSearchText = (e) => {
        e.preventDefault();
        setSearchText(e.target.search.value);
    }

    const handleOrderStatus = (orderId, status) => {
        if (status) {
            setLoading(true);

            fetch(`${process.env.REACT_APP_SERVER_URL}order/status/${orderId}`, {
                method: 'PATCH',
                headers: {
                    'content-Type': 'application/json',
                    authorization: `Bearer ${localStorage.getItem('accessToken')}`
                },
                body: JSON.stringify({ status })

            })
                .then(res => {
                    if (res.status === 401 || res.status === 403) {
                        localStorage.removeItem('accessToken');
                        localStorage.removeItem('userId');
                        navigate('/login');
                        toast.error('Forbidden/Unauthorized access!');
                    }
                    return res.json();
                })
                .then(data => {
                    setLoading(false);
                    if (data.success === true) {
                        refetch();
                        toast.success(data.message);
                    }
                    if (data.success === false) {
                        toast.error(data.message);
                    }
                })
        }
    }

    // delete return data
    const handleDeleteOrder = (orderId) => {
        sendDeleteRequest(`${process.env.REACT_APP_SERVER_URL}order/${orderId}`, setLoading, refetch);
    }

    // datas
    const columns = [
        {
            name: 'Invoice No',
            selector: row => row.invoiceId,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Customer',
            selector: row => <span>{row?.userId?.name}</span>,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Customers Email',
            selector: row => <span>{row.userId?.email}</span>,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Due',
            selector: row => row.due > 0 ? <div className='due-status bg-danger'>{row.due} TK</div> : <div className='paid-status'>Paid</div>,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Date',
            selector: row => row.createdAt.substring(0, 10),
            filterable: true,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status === false ?
                <div className='due-status cursor-ponter' onClick={() => handleOrderStatus(row._id, true)}>Processing</div>
                : <div className='paid-status'>Delivered</div>,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => (
                <>
                    <button className='btn account-btn me-2' onClick={() => handleViewOrder(row._id)}>
                        <AiOutlineEye className='text-white' />
                    </button>
                    <button className='btn btn-danger' onClick={() => handleDeleteOrder(row._id)}><IoTrash /></button>
                </>
            ),
        },
    ];

    // loading
    if (isLoading || loading) { return <Loading getHeight={'80vh'} /> }

    return (
        <div>

            {/* ================== breadcrumb ==================== */}
            <Breadcrumb
                indexTitle={'Orders'}
                indexLink={'/dashboard/orders'}
            />
            {/* ================================================== */}


            {/* main content */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card py-4 px-3">

                        <DataTable
                            columns={columns}
                            data={orders?.orders}
                            highlightOnHover
                            progressPending={isLoading}
                            pagination
                            paginationPerPage={15}
                            subHeader
                            subHeaderComponent={
                                <SearchForm 
                                    searchText={searchText}
                                    handleSearchText={handleSearchText}
                                    placeholder={'Type invoice/name/email & hit enter'}
                                />
                            }
                        />

                    </div>
                </div>
            </div>
            {/* main content */}
        </div>
    );
};

export default Orders;