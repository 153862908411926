import React from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';

const SearchForm = ({ searchText, handleSearchText, placeholder }) => {
    return (
        <Form action='' onSubmit={handleSearchText} style={{ width: '380px' }}>
            <InputGroup className="mb-3">
                <Form.Control placeholder={placeholder} type='text' id='search' />
                <Button variant="btn primary-btn" type='submit' > {searchText ? 'Searching: ' + searchText : " Search"} </Button>
            </InputGroup>
        </Form>
    );
};

export default SearchForm; 