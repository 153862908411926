import React, { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import Breadcrumb from '../../../Utilities/Breadcrumb';
import Loading from '../../Shared/Loading/Loading';
import AccountPrint from './AccountPrint';
import AddCashModal from './AddCashModal';

const Account = () => {

    let { userId } = useParams();
    const componentRef = useRef();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [perPage, setPerPage] = useState(20);
    // add cash modal
    const [showModal, setShowModal] = useState(false);
    const handleModalClose = () => setShowModal(false);
    const handleModalkShow = () => setShowModal(true);

    const pageStyle = `
        @page {
            size: A4;
            margin: 5mm 0mm;
        }

        @media all {
            .page-break {
                display: block;
            }
            .pagebreak {

            }
        }

        @media print {
            html, body {
                height: initial !important;
                overflow: initial !important;
                -webkit-print-color-adjust: exact;
            }

            .pagebreak {
                page-break-before: always;
            }
            
            .page-break {
                margin-top: 1rem;
                display: block;
                page-break-before: auto;
            }
        }`;

    // load account data
    const { data, isLoading, refetch } = useQuery(['account', userId], () =>
        fetch(`${process.env.REACT_APP_SERVER_URL}account/${userId}`, {
            headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
            .then(res => {
                if (res.status === 401 || res.status === 401) {
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('userId');
                    navigate('/login');
                    toast.error('Forbidden/Unauthorized access!');
                }
                return res.json();
            }))


    // loading
    if (isLoading || loading) { return <Loading getHeight={'80vh'} /> }

    return (
        <>

            {/* ================== breadcrumb ==================== */}
            <Breadcrumb
                indexTitle={'Users'}
                indexLink={'/dashboard/users'}
                editPageTitle={`${data?.accountInfo?.userId?.name}`}
                editPageLink={`/dashboard/user/account/${userId}`}
            />
            {/* ================================================== */}

            {/* ================== main content ==================== */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card py-4 px-3">

                        <div className="account-btns d-flex justify-content-between mb-4">
                            <form action="">
                                <select name="perPage" id="perPage" className='form-control' style={{ width: '100px' }} value={perPage}
                                    onChange={(e) => setPerPage( e.target.value )}
                                >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="250">250</option>
                                    <option value="500">500</option>
                                    <option value="1000">1000</option>
                                </select>
                            </form>
                            <div>
                                <ReactToPrint
                                    trigger={() => <button type='button' className='btn print-btn'>Print this out!</button>}
                                    content={() => componentRef.current}
                                    pageStyle={pageStyle}
                                />
                                 <button type='button' className='btn primary-btn ms-4' onClick={handleModalkShow}>Add Cash</button>
                            </div>
                           
                        </div>

                        <hr className='mt-0' />

                        <div ref={componentRef}>
                            <AccountPrint data={data} perPage={perPage} />
                        </div>

                    </div>
                </div>
            </div>
            {/* ===================================================== */}

            <AddCashModal
                refetch={refetch}
                showModal={showModal}
                setLoading={setLoading}
                handleModalClose={handleModalClose}
                userId={data?.accountInfo?.userId?._id}
                userName={data?.accountInfo?.userId?.name}
            />

        </>
    );
};

export default Account;