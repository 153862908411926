import React, { useRef } from 'react'
import { toast } from 'react-hot-toast';
import { useQuery } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import Loading from '../../Shared/Loading/Loading';
import PrintSupOrder from './PrintSupOrder';

function SupOrderView() {


  let { orderId } = useParams();
  const navigate = useNavigate();
  const componentRef = useRef();

  // getting specific category by id
  const { data: order, isLoading, refetch } = useQuery(['singleSuppOrder', orderId], () =>
    fetch(`${process.env.REACT_APP_SERVER_URL}supplier-order/${orderId}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    })
      .then(res => {
        if (res.status === 401 || res.status === 401) {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('userId');
          navigate('/login');
          toast.error('Forbidden/Unauthorized access!');
        }
        return res.json();
      }))

  const pageStyle = `
       @page {
           size: A4;
           margin: 5mm 0mm;
       }

       @media all {
           .page-break {
               display: block;
           }
           .pagebreak {

           }
       }

       @media print {
           html, body {
               height: initial !important;
               overflow: initial !important;
               -webkit-print-color-adjust: exact;
           }

           .pagebreak {
               page-break-before: always;
           }
           
           .page-break {
               margin-top: 1rem;
               display: block;
               page-break-before: auto;
           }
       }`;

  // loading
  if (isLoading) { return <Loading getHeight={'80vh'} /> }

  return (
    <>

      {/* breadcrumb */}
      <div className="page-header">
        <div className="card breadcrumb-card">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-6">
              <h3 className="page-title">Order</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/dashboard/supplier/orders">Supplier Orders</Link>
                </li>
                <li className="breadcrumb-item active-breadcrumb">
                  <Link to={`/dashboard/supplier/order/${orderId}`}>{order?.order.invoiceId} Order</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-5">
              <div className='text-end me-2'>
                <ReactToPrint
                  trigger={() => <button type='button' className='btn print-btn me-3'>Print this out!</button>}
                  content={() => componentRef.current}
                  pageStyle={pageStyle}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* breadcrumb */}

              {/* main content */}
              <div className="row">
                <div className="col-md-12">
                    <div className="card py-4 px-3">


                        {/* print elements */}
                        <div className=' mt-4' style={{ border: '1px solid grey' }}>
                            <div className="" ref={componentRef} >

                                <PrintSupOrder
                                    order={order?.order}
                                />

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* main content */}

    </>
  )
}

export default SupOrderView