import React from 'react';
import { Link } from 'react-router-dom';
import './notFound.css';

const NotFound = () => {
    return (
        <div className='not__found'>
            <div className="container text-center">
                <img width={400} src="https://i.ibb.co/WpvZX2K/76706-404-error-page.gif" alt="" />
                <div className="h3 py-3">Page Not Found</div>
                <Link className='btn primary-btn' to={'/dashboard'}>Back to Dashboard</Link>
            </div>
           
        </div>
    );
};

export default NotFound;