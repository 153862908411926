import React from 'react';

const Home = () => {

    window.location = '/dashboard';

    return (
        <div>

        </div>
    );
};

export default Home;