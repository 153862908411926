import React, { useEffect } from 'react';
import { useState } from 'react';
import DataTable from 'react-data-table-component';
import useUsers from '../../../hooks/useUsers';
import Breadcrumb from '../../../Utilities/Breadcrumb';
import Loading from '../../Shared/Loading/Loading';
import SearchFormLive from '../../../Utilities/SearchFormLive';
import { useNavigate } from 'react-router-dom';
import { AiOutlineEye } from 'react-icons/ai';
import { MdAccountBox } from "react-icons/md";
import { IoCogOutline, IoTrash } from 'react-icons/io5';
import { toast } from 'react-hot-toast';
import swal from 'sweetalert';
import Button from '../../../Utilities/Button';

const Suppliers = () => {

    // global
    const supplier = 'Supplier';
    const navigate = useNavigate();

    // users/roles info
    const { users, usersLoading, usersFetch } = useUsers('');

    // states
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);
    const [filterData, setFilterData] = useState([]);

    // filter data by search text
    useEffect(() => {
        if (searchText) {
            // get data by role
            const filterWithourSup = users?.users?.filter((el) => el?.role.name.toLowerCase() === supplier.toLowerCase());

            setFilterData(filterWithourSup.filter((el) =>
                (el.name)?.match(new RegExp(searchText, "i")) || (el.email)?.match(new RegExp(searchText, "i")) ||
                (el.mobile)?.match(new RegExp(searchText, "i")) || (el.address)?.match(new RegExp(searchText, "i"))
            ));
        } else {
            setFilterData(users?.users.filter((item) => item.role?.name.toLowerCase() === supplier.toLowerCase()));
        }
    }, [searchText, users?.users]);

    // view single user
    const handleViewUser = async (userId) => {
        navigate(`/dashboard/view-user/${userId}`)
    }

    // view suuplier account
    const handleUserAccount = async (userId) => {
        navigate(`/dashboard/supplier/account/${userId}`)
    }

    // edit a user
    const handleEditUser = async (userId) => {
        navigate(`/dashboard/edit-user/${userId}`)
    }

    // edit a user
    const addOrderNav = () => {
        navigate(`/dashboard/suppliers/add-order`)
    }  
    
    // edit a user
    const viewOrderNav = () => {
        navigate(`/dashboard/supplier/orders`)
    }

    // delete user
    const handleDeleteUser = async (userId) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this imaginary file!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                setLoading(true);
                const deleteUserConfirm = async () => {
                    fetch(`${process.env.REACT_APP_SERVER_URL}user/${userId}`, {
                        method: 'DELETE',
                        headers: {
                            authorization: `Bearer ${localStorage.getItem('accessToken')}`
                        }
                    })
                        .then(res => {
                            if (res.status === 401 || res.status === 403) {
                                localStorage.removeItem('accessToken');
                                localStorage.removeItem('userId');
                                navigate('/login');
                                toast.error('Forbidden/Unauthorized access!');
                            }

                            return res.json();
                        })
                        .then(data => {
                            setLoading(false);
                            if (data.message && data.success === true) {
                                usersFetch();
                                if (localStorage.getItem('userId') === userId) {
                                    localStorage.removeItem('userId');
                                }
                                toast.success(data.message);
                            }
                            if (data.message && data.success === false) {
                                toast.error(data.message);
                            }
                        })
                }
                deleteUserConfirm();
            }
        });
    }

    // datas
    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            filterable: true,
            sortable: true,
            maxWidth: '250px'
        },
        {
            name: 'Email',
            selector: row => row.email,
            filterable: true,
            maxWidth: '220px'
        },
        {
            name: 'Balance',
            selector: row => <span className={`${row.accountId.balance > 0 ? 'text-danger' : 'text-success'} p-2 rounded-pill`}>{row.accountId.balance} ৳</span>,
            filterable: true,
            center: true,

        },
        {
            name: 'Role',
            selector: row => <span className={`${row.role?.name ? 'bg-info' : 'bg-danger'} p-2 text-white rounded-pill text-capitalize`}>{row.role?.name ? row.role?.name : 'None'}</span>,
            // maxWidth: '200px'
        },
        {
            name: 'Action',
            cell: row => (
                <>
                    <button className='btn account-btn me-2' onClick={() => handleUserAccount(row._id)}><MdAccountBox /></button>
                    <button className='btn view-btn me-2' onClick={() => handleViewUser(row._id)}><AiOutlineEye /></button>
                    <button className='btn btn-warning text-white me-2' onClick={() => handleEditUser(row._id)}><IoCogOutline /></button>
                    <button className='btn btn-danger' disabled={row.email === 'admin@gmail.com'} onClick={() => handleDeleteUser(row._id)}><IoTrash /></button>
                </>
            ),
        },
    ];

    // loading
    if (usersLoading || loading) { return <Loading getHeight={'80vh'} /> }

    return (
        <div>

            {/* ================== breadcrumb ==================== */}
            <Breadcrumb
                indexTitle={'Suppliers'}
                indexLink={'/dashboard/suppliers'}
                adressLink={'Add New Supplier'}
                address={'/dashboard/add-user'}
            />
            {/* ================================================== */}

            <div className='card py-4 px-3 mb-4 '>
                <div className='d-flex justify-content-end'>
                    <div className='me-3'>
                        <Button title={'Orders'} onClick={viewOrderNav} />
                    </div>
                    <div className=''>
                        <Button title={'Add Order'} onClick={addOrderNav} />
                    </div>
                </div>
            </div>


            {/* ================== main ==================== */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card py-4 px-3 users_datatable">

                        <DataTable
                            columns={columns}
                            data={filterData}
                            highlightOnHover
                            progressPending={usersLoading}
                            pagination
                            subHeader
                            persistTableHead={true}
                            subHeaderComponent={
                                <SearchFormLive
                                    placeholder={`Search by supplier name`}
                                    handleChange={(e) => setSearchText(e.target.value)}
                                />
                            }
                        />

                    </div>
                </div>
            </div>
            {/* ================================================== */}
        </div>
    );
};

export default Suppliers;