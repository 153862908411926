import React from 'react';
import { Table } from 'react-bootstrap';
import { IoCallSharp, IoLocationSharp, IoMail } from 'react-icons/io5';
import fabLogo from '../../../assets/icon.png';
import { date } from '../../../hooks/helpers';
import useAuth from '../../../hooks/useAuth';

const SupplierPrint = ({ componentRef, products, uniqueInvoiceSupplier, data, discount, total, subTotal, cash, due }) => {

    // getting specific user by id & logged user
    const { loggedUser } = useAuth();

    return (

        <div className='mt-4' style={{ border: '1px solid grey' }}>
            <div className="cartItems" ref={componentRef} >

                <div className="print-container">

                    <div className="invoice-header p-5">
                        <div className="row">
                            <div className="col-6">
                                <div className="logo">
                                    <div className="d-flex">
                                        <img src={fabLogo} width={50} height={50} alt="Logo" />
                                        <div className="name-title px-2">
                                            <h5 className='m-0'>TAMIM ENTERPRISE</h5>
                                            <p>Tiles and Ceramics</p>
                                        </div>
                                    </div>


                                </div>

                            </div>
                            <div className="col-6">
                                <h5 className="">INVOICE</h5>
                                <div className="company-inforamtion">

                                    <p> <span> <IoLocationSharp /></span> DubolHati Road, Hat Naogaon, Naogaon </p>
                                    <p> <span> <IoCallSharp /></span>  01753389555, 01791438043</p>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="invoice_details pt-4 px-5 pb-1">
                        <Table bordered>
                            <tbody>
                                <tr>
                                    <td>
                                        <p>Invoice to,</p>
                                        <strong>{data?.name} </strong>
                                        <p> <IoMail /> {data?.email}</p>
                                        <p> <IoLocationSharp /> {data?.address}</p>
                                        <p> <IoCallSharp /> {data?.mobile}</p>
                                    </td>
                                    <td>
                                        <p>Invoice info</p>
                                        <p>Order ID: <strong>{uniqueInvoiceSupplier}</strong> </p>
                                        <p>Date: {date}</p>

                                        <p>Checkout by: {loggedUser?.name}</p>
                                    </td>
                                    <td>
                                        <p>Total</p>
                                        <h4>{total} BDT</h4>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                    <div className='px-5'>
                        <div className=" table-responsive my-2 ">
                            <table className="table table-bordered">
                                <tbody>
                                    <tr className='csbg_secondery text-white'>
                                        <th scope="col" className='font-14'>Product Name</th>
                                        <th scope="col" className='font-14'>Price</th>
                                        <th scope="col" className='font-14'>Quantity</th>
                                        <th scope="col" className='font-14'>Total</th>
                                    </tr>
                                    {
                                        products.map((item, index) => <tr key={index}>
                                            <td className='font-14'>{item.name}</td>
                                            <td className='font-14'>{item?.price} BDT</td>
                                            <td className='font-14'>{item?.quantity} PC</td>
                                            <td className='font-14'>{item.total} BDT</td>
                                        </tr>)
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className='page-break' />

                        <div className='d-flex justify-content-between'>

                            <div>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr >
                                            <td className='font-14'> Cash</td>
                                            <td className='font-14'>{cash} BDT </td>
                                        </tr>
                                        <tr >
                                            <td className='font-14'> Due:</td>
                                            <td className='font-14'>{due} BDT</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr >
                                            <td className='font-14'> Subtotal</td>
                                            <td className='font-14'>{subTotal} BDT </td>
                                        </tr>
                                        <tr >
                                            <td className='font-14'> Discount:</td>
                                            <td className='font-14'>{discount} BDT</td>
                                        </tr>
                                        <tr >
                                            <td className='font-14'> Total:</td>
                                            <td className='font-14'><strong>{total} BDT</strong></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                    <div className="p-5"></div>

                    <div className='print-footer p-2'>
                        <hr className='csbg_primary' />
                        <p className='my-1 text-center font-12'>Thank you for choosing Tamim Enterprise products. We believe you will be satisfied by our service</p>
                        <p className='my-1 text-center font-12'>Software developed by kalaTabij. Phone: +8801689201370, +8801713644570</p>
                    </div>

                </div>

            </div>
        </div>
    );
};

export default SupplierPrint;