import React from 'react';
import { useNavigate } from 'react-router-dom';
import useProducts from '../../../hooks/useProducts';
import toast from 'react-hot-toast';
import swal from 'sweetalert';
import { IoTrashOutline } from "react-icons/io5";

const OffcanvasCartItem = ({ data, cartFetch, setLoading }) => {

    const navigate = useNavigate();

    // product fetch
    const { productsFetch } = useProducts('');
    const perProductTotal = data?.orderPrice * data?.orderQuantity;
    const productId = data.productId._id;

    // remove cart item
    const handleRemoveCartItem = (cartId, getData) => {

        const newQuantity = getData.productId.quantity + getData?.orderQuantity;
        const data = { newQuantity: newQuantity, productId: productId, cartId: cartId }

        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this imaginary file!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                setLoading(true);
                const removeProductConfirm = async () => {
                    fetch(`${process.env.REACT_APP_SERVER_URL}cart/remove`, {
                        method: 'POST',
                        headers: {
                            'content-Type': 'application/json',
                            authorization: `Bearer ${localStorage.getItem('accessToken')}`
                        },
                        body: JSON.stringify(data)
                    })
                        .then(res => {
                            if (res.status === 401 || res.status === 403) {
                                localStorage.removeItem('accessToken');
                                localStorage.removeItem('userId');
                                navigate('/login');
                                toast.error('Forbidden/Unauthorized access!');
                            }
                            return res.json();
                        })
                        .then(data => {
                            setLoading(false);
                            if (data.success === true) {
                                cartFetch();
                                productsFetch();
                                toast.success(data.message);
                            }
                            if (data.success === false) {
                                toast.error(data.message);
                            }
                        })
                }
                removeProductConfirm();
            }
        });
    }

    return (
        <div className='d-flex align-items-center justify-content-between offcanvascart p-2 my-3 border '>
            <div className="product d-flex">
                <div className="product-image">
                    {/* <img width={100} src={productImage} alt="" srcSet="" /> */}

                </div>
                <div className="product_cart_data p-2">
                    <h6 className='text-info'>{data?.productId?.name} </h6>
                    <h6>{data?.orderPrice} BDT & {data?.orderQuantity} PC</h6>
                    <small>{perProductTotal} Taka</small>

                </div>
            </div>
            <div className="action_button">
                <div role="button" className="h4 text-danger pe-auto p-2" onClick={() => handleRemoveCartItem(data._id, data)}> <IoTrashOutline /></div>
            </div>
        </div>
    );
};

export default OffcanvasCartItem;