import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { IoEyeOutline, IoFileTray, IoCartOutline } from "react-icons/io5";
import useDelete from '../../../hooks/useDelete';
import useCart from '../../../hooks/useCart';
import toast from 'react-hot-toast';

const PosProductLoop = ({
    product,
    setDeleteLoading,
    productsFetch,
    restockLoading,
    setRestockLoading,
    cartLoading,
    setCartLoading
}) => {

    const navigate = useNavigate();
    const { cartFetch } = useCart();
    const { sendDeleteRequest } = useDelete();
    const { _id, name, img, quantity, price } = product;

    // cart modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [cartData, setCartData] = useState({ productId: _id, orderPrice: 0, orderQuantity: 0 })

    // Product Details  modal
    const [showDetails, setShowDetails] = useState(false);
    const handleShowDetailsModalClose = () => setShowDetails(false);
    const handleShowDetailsModal = () => setShowDetails(true);

    // restock modal
    const [showRestockModal, setRestockModal] = useState(false);
    const handleRestockClose = () => setRestockModal(false);
    const handleRestockShow = () => setRestockModal(true);
    const [restockData, setRestockData] = useState({ restockQuantity: 0 })

    // add to cart
    const handleAddToCart = async (cartProductId) => {
        const decreasedProductQuantity = quantity - cartData.orderQuantity
        const data = { ...cartData, decreasedProductQuantity }

        if (cartData.orderQuantity > quantity) {
            toast.error('Quantity should be less than stock quantity!');
        } else if (cartData.orderQuantity < 1) {
            toast.error('Order quantity can not be 0!');
        } else {
            setCartLoading(true);
            fetch(`${process.env.REACT_APP_SERVER_URL}cart/create`, {
                method: 'POST',
                headers: {
                    'content-Type': 'application/json',
                    authorization: `Bearer ${localStorage.getItem('accessToken')}`
                },
                body: JSON.stringify(data)
            })
                .then(res => {
                    if (res.status === 401 || res.status === 403) {
                        localStorage.removeItem('accessToken');
                        localStorage.removeItem('userId');
                        navigate('/login');
                        toast.error('Forbidden/Unauthorized access!');
                    }
                    return res.json();
                })
                .then(data => {
                    setShow(false);
                    setCartLoading(false);
                    if (data.message && data.success === true) {
                        cartFetch();
                        productsFetch();
                        setCartData({ productId: _id, orderPrice: 0, orderQuantity: 0 });
                        toast.success(data.message);
                    }
                    if (data.message && data.success === false) {
                        toast.error(data.message);
                    }
                })
        }
    }

    // restock product
    const handleRestockProduct = (productId) => {
        setRestockModal(false);
        setRestockLoading(true);

        const restockQuantity = parseInt(restockData.restockQuantity);
        const newQuantity = restockQuantity + quantity;
        const data = { newQuantity, restockQuantity, productName: name }

        fetch(`${process.env.REACT_APP_SERVER_URL}product/restock/${productId}`, {
            method: 'PATCH',
            headers: {
                'content-Type': 'application/json',
                authorization: `Bearer ${localStorage.getItem('accessToken')}`
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.status === 401 || res.status === 403) {
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('userId');
                    navigate('/login');
                    toast.error('Forbidden/Unauthorized access!');
                }
                return res.json();
            })
            .then(data => {
                setRestockLoading(false);
                productsFetch();
                if (data.message && data.success === true) {
                    setRestockData({ ...restockData, restockQuantity: 0 })
                    toast.success(data.message);
                }
                if (data.message && data.success === false) {
                    toast.error(data.message);
                }
            })
    }

    // edit product
    const handleEditProduct = (productId) => {
        navigate(`/dashboard/edit-product/${productId}`)
    }

    // delete product
    const handleDeleteProduct = (productId) => {
        sendDeleteRequest(`${process.env.REACT_APP_SERVER_URL}product/${productId}`, setDeleteLoading, productsFetch);
    }

    return (
        <div className='col-md-4 col-xl-3 col-xxl-2'>
            <div className="card">
                <div className="product__image">

                    {/* <img src={`${process.env.REACT_APP_PRODUCT_DEMO_IMAGE}`} className="card-img-top" alt={name} /> */}
                    <img src={img ? `${process.env.REACT_APP_SERVER_URL}${img}` : `${process.env.REACT_APP_PRODUCT_DEMO_IMAGE}`} className="card-img-top" alt={name} />

                    <div className="product_name">
                        <p>{name}</p>
                    </div>
                    <div onClick={handleShowDetailsModal} className="view_icon"> <IoEyeOutline /> </div>
                    {/* <div className={`${quantity > 0 ? 'view_stock' : 'view_stock bg-danger'}`}> <IoFileTray /> {quantity > 0 ? quantity : 'Stock out'} </div> */}
                    <div className={`view_stock`}> <IoFileTray /> {price} BDT </div>
                </div>
                <div className="">
                    <div className="cart-area">
                        <button className='cart-btn col-12' onClick={handleShow} disabled>
                            <IoCartOutline /> Add To cart
                        </button>
                    </div>
                </div>
            </div>


            {/* Show Product  Details Modal*/}
            <Modal show={showDetails} onHide={handleShowDetailsModalClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="product__image">
                                    <img src={img ? `${process.env.REACT_APP_SERVER_URL}${img}` : `${process.env.REACT_APP_PRODUCT_DEMO_IMAGE}`} className="card-img-top" alt={name} />
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="product-details-area">
                                    <h5>{name}</h5>
                                    <hr />
                                    <p className='my-2'><strong>Price:</strong> {price} BDT</p>
                                    <p className='my-2'><strong>In Stock:</strong> {quantity > 0 ? quantity : <span className='text-danger'>Stock out</span>} </p>
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <p className='my-2'><strong>Manage  </strong></p>
                                    <div className="buttons">

                                        {/* <button className='btn primary-btn me-3' onClick={handleRestockShow}>Re-stock</button> */}
                                        <button className='btn primary-btn me-3' onClick={() => handleEditProduct(_id)}>Edit</button>
                                        <button className='btn btn-danger me-3' onClick={() => handleDeleteProduct(_id)}>Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="col-sm-12 mt-4">

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleShowDetailsModalClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
            {/* ----------------------- */}





            {/* add to cart modal */}
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-sm-12">
                        <label htmlFor="orderPrice" className="form-label">Product Order Price<span className='text-danger'>*</span></label>
                        <input type="number" className="form-control" id="orderPrice" placeholder="Product order price"
                            value={cartData?.orderPrice}
                            required
                            onChange={(e) => setCartData({ ...cartData, orderPrice: e.target.value && parseFloat(e.target.value) })}
                        />
                    </div>
                    <div className="col-sm-12 mt-4">
                        <label htmlFor="orderQuantity" className="form-label">Order quantiy<span className='text-danger'>*</span></label>
                        <input type="text" className="form-control" id="orderQuantity" placeholder="Product order quantity"
                            value={cartData?.orderQuantity}
                            required
                            onChange={(e) => setCartData({ ...cartData, orderQuantity: e.target.value && parseInt(e.target.value) })}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => handleAddToCart(_id)} disabled={cartLoading}>
                        <div className={`${cartLoading && 'd-flex align-items-center justify-content-end'}`}>
                            {cartLoading && <span class="custom__loader_btn me-2"></span>} Add to cart
                        </div>
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* restock modal */}
            <Modal show={showRestockModal} onHide={handleRestockClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-sm-12">
                        <label htmlFor="restockQuantity" className="form-label">Amount to add quantity<span className='text-danger'>*</span></label>
                        <input type="number" className="form-control" id="restockQuantity" placeholder="Amount of quantity to add"
                            value={restockData?.restockQuantity}
                            required
                            onChange={(e) => setRestockData({ ...restockData, restockQuantity: e.target.value })}
                        />

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleRestockClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => handleRestockProduct(_id)} disabled={restockLoading}>
                        <div className={`${restockLoading && 'd-flex align-items-center justify-content-end'}`}>
                            {restockLoading && <span class="custom__loader_btn me-2"></span>} Restock
                        </div>
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default PosProductLoop;