import React from 'react';
import fabLogo from '../../../assets/icon.png';
import { IoLocationSharp, IoCallSharp, IoMail } from "react-icons/io5";
import { Table } from 'react-bootstrap';
import { shopAddress, shopCategory, shopNumber, shopTitle } from '../../../configs/constants';

const PrintOrder = ({ order }) => {
    return (
        <div className="print-container">

            <div className="invoice-header p-5">
                <div className="row">

                    <div className="col-6">
                        <div className="logo">
                            <div className="d-flex">
                                <img src={fabLogo} width={50} height={50} alt="Logo" />
                                <div className="name-title px-2">
                                    <h5 className='m-0'>{shopTitle}</h5>
                                    <p>{shopCategory}</p>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div className="col-6">
                        <h5 className="">INVOICE</h5>
                        <div className="company-inforamtion">
                            <p> <span> <IoLocationSharp /></span> {shopAddress} </p>
                            <p> <span> <IoCallSharp /></span>  {shopNumber}</p>
                        </div>
                    </div>

                </div>
            </div>

            <div className="invoice_details pt-4 px-5 pb-1">
                <Table bordered>
                    <tbody>
                        <tr>
                            <td>
                                <p>Invoice to,</p>
                                <strong>{order?.order?.userId?.name} </strong>
                                <p> <IoMail /> {order?.order?.userId?.email}</p>
                                <p> <IoLocationSharp /> {order?.order?.userId?.address}</p>
                                <p> <IoCallSharp /> {order?.order?.userId?.mobile}</p>

                            </td>
                            <td>
                                <p>Invoice info</p>
                                <p>Order ID: <strong>{order?.order?.invoiceId}</strong> </p>
                                <p>Status: <strong>{order?.order?.status === true ? 'Delivered' : 'Processing'}</strong> </p>
                                <p>Date: {order?.order?.createdAt.slice(0, 10)}</p>
                                {/* <p>Checkout by: {loggedUser?.name}</p> */}
                            </td>
                            <td>
                                <p>Total</p>
                                <h4>{order?.order?.total} BDT</h4>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>

            <div className='px-5'>
                <div className=" table-responsive my-2 ">
                    <table className="table table-bordered">
                        <tbody>
                            <tr className='csbg_secondery text-white'>
                                <th scope="col" className='font-14'>Product Name</th>
                                <th scope="col" className='font-14'>Price</th>
                                <th scope="col" className='font-14'>Quantity</th>
                                <th scope="col" className='font-14'>Total</th>
                            </tr>
                            {
                                order?.order.orderPorducts.map(item => <tr key={item._id}>
                                    <td className='font-14'>{item?.productId?.name}</td>
                                    <td className='font-14'>{item?.orderPrice} BDT</td>
                                    <td className='font-14'>{item?.orderQuantity} PC</td>
                                    <td className='font-14'>{item?.orderPrice * item?.orderQuantity} BDT</td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div>

                <div className='page-break' />

                <div className='d-flex justify-content-between'>
                    <div>
                        <table className="table table-bordered">
                            <tbody>

                                <tr >
                                    <td className='font-14'> Cash Received:</td>
                                    <td className='font-14'>{order?.order?.cash} BDT</td>
                                </tr>
                                <tr >
                                    <td className='font-14'> Due:</td>
                                    <td className='font-14'>{order?.order?.due} BDT</td>
                                </tr>
                                {/* <tr >
                                <td className='font-14'> Balance:</td>
                                <td className='font-14'>{order?.order?.userId?.balance} BDT</td>
                            </tr> */}

                            </tbody>
                        </table>

                    </div>
                    <div>
                        <table className="table table-bordered">
                            <tbody>

                                <tr >
                                    <td className='font-14'> Subtotal</td>
                                    <td className='font-14'>{order?.order?.subTotal} BDT </td>
                                </tr>
                                <tr >
                                    <td className='font-14'> Labor Charge:</td>
                                    <td className='font-14'>{order?.order?.laborCharge} BDT</td>
                                </tr>
                                <tr >
                                    <td className='font-14'> Discount:</td>
                                    <td className='font-14'>{order?.order?.discount} BDT</td>
                                </tr>
                                <tr >
                                    <td className='font-14'> Total:</td>
                                    <td className='font-14'><strong>{order?.order?.total} BDT</strong></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="p-5"></div>

            <div className='print-footer p-2'>
                <hr className='csbg_primary' />
                <p className='my-1 text-center font-12'>Thank you for choosing our products. We believe you will be satisfied by our service</p>
                <p className='my-1 text-center font-12'>Software developed by kalaTabij. Phone: +8801689201370, +8801713644570</p>
            </div>

        </div>
    );
};

export default PrintOrder;