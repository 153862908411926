import React, { useState } from 'react';
import { Container, Row, Tab, Tabs } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from "../../assets/logo.png";
import './about.css';

const About = () => {
    const [key, setKey] = useState('home');
    return (
        <div>
            <Container>
                <div className="about-page py-5">
                    <div className="about text-center">
                        <Link to={'/dashboard'}><img width={250} src={logo} alt="" srcSet="" /></Link>
                        <div className="col-md-4 py-5 mx-auto">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, inventore suscipit. Iste minus, officia quae facere omnis earum ipsam corrupti!</p>
                        </div>

                    </div>

                    <div className="_about_detals py-5 col-md-6 mx-auto">
                        <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3"  >
                            <Tab eventKey="home" title="Credits">
                                <div className="credit_area">


                                    <div className="py-3">
                                        <p><strong>Developed by: </strong>Team - KalaTabij</p>
                                    </div>
                                    <Row>
                                        <div className="col-6">
                                            <div className="team_member_widget">
                                                <div className="team_member_image">
                                                    <img className='img-fluid' src="https://res.cloudinary.com/tusar/image/upload/v1660367395/tusar/soumik_jfkmlx.webp" alt="" />
                                                </div>
                                                <div className="team_member_details py-3 px-2">
                                                    <h5>Soumik Ahammed</h5>
                                                    <small>Full Stack Developer</small>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-6">
                                            <div className="team_member_widget">
                                                <div className="team_member_image">
                                                    <img className='img-fluid' src="https://res.cloudinary.com/tusar/image/upload/v1660367028/tusar/tusar-bg_sf4lxf.webp" alt="" />
                                                </div>
                                                <div className="team_member_details py-3 px-2">
                                                    <h5>Arifuzzaman Tusar</h5>
                                                    <small>Full Stack Developer</small>
                                                </div>
                                            </div>
                                        </div>



                                    </Row>
                                </div>
                            </Tab>
                            <Tab eventKey="profile" title="Changalog">
                                <div className="changalog">
                                    <h5>Design-1.0</h5>
                                    <ul>
                                        <li>Dashbord ReOrganized, Recolored</li>
                                        <li>Font Family (Inter)</li>
                                        <li>Global Compent Styles</li>
                                        <li>Cart</li>
                                        <li>CartCanvas</li>
                                        <li>Data-Table responsive</li>
                                        <li>DataTable Button Icons</li>
                                        <li>Some Solves of issue 1.0</li>
                                    </ul>










                                </div>
                            </Tab>

                        </Tabs>
                    </div>
                </div>
            </Container>
            <footer>
                <div className="footer_area">
                    <div className="p-2 text-center">
                        <small> Proudly! Team Kala Tabij, Rajshahi</small>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default About;