import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import toast from "react-hot-toast";

const useProducts = (searchText) => {
    const navigate = useNavigate();

    const { data: products, isLoading, refetch, error } = useQuery(['products', searchText], () =>
    fetch(`${process.env.REACT_APP_SERVER_URL}product/index?name=${searchText}`, {
        headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
    })
        .then(res => {
            if (res.status === 401 || res.status === 401) {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('userId');
                navigate('/login');
                toast.error('Forbidden/Unauthorized access!', { duration: 2000 });
            }
            return res.json();
        }))

    return { products, productsLoading: isLoading, productsFetch: refetch, productsError: error };
}

export default useProducts;