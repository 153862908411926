import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AiOutlineEye } from "react-icons/ai";
import Loading from '../../Shared/Loading/Loading';
import DataTable from 'react-data-table-component';
import useUser from '../../../hooks/useUser';
import Breadcrumb from '../../../Utilities/Breadcrumb';

const ViewUser = () => {

    let { userId } = useParams();
    const navigate = useNavigate();

    // getting specific user by id
    const { user, userLoading } = useUser(userId); 

    // view single order
    const handleViewOrder = (orderId) => {
        user?.user?.role?.name.toLowerCase() === 'supplier' ? navigate(`/dashboard/supplier/order/${orderId}`) :
        navigate(`/dashboard/order/view/${orderId}`)
    }

    const columns = [
        {
            name: 'Order Id',
            selector: row => row.invoiceId,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Total',
            selector: row => row.total,
            filterable: true,
            center: true,
        },
        {
            name: 'Due',
            selector: row => row.due,
            center: true,
        },
        {
            name: 'Date',
            selector: row => row.createdAt.substring(0, 10),
            center: true,
        },
        {
            name: 'Action',
            cell: row => (
                <>
                    <button className='btn account-btn me-2 text-white' onClick={() => handleViewOrder(row._id)}><AiOutlineEye /></button>
                </>
            ),
            center: true,
        },
    ];

    if (userLoading) { return <Loading /> }

    return (
        <div>

            {/* ================== breadcrumb ==================== */}
            <Breadcrumb
                indexTitle={'Users'}
                indexLink={'/dashboard/users'}
                editPageTitle={`${user?.user.name}`}
                editPageLink={`/dashboard/view-user/${userId}`}
            />
            {/* ================================================== */}

            {/* ================== main content ================== */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card py-4 px-3">

                        {
                            user?.user.img ? <img width={100} height={100}
                                src={`${process.env.REACT_APP_SERVER_URL}${user?.user.img}`} alt={user?.user.name} />
                                : <p className='text-danger'>No Image</p>
                        }

                        <div className="row my-4">
                            <div className="col-md-4">

                                <div className="row mb-1">
                                    <div className="col-sm-3"><p>Name</p></div>
                                    <div className="col-sm-9">: {user?.user?.name}</div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-sm-3"><p>Email</p></div>
                                    <div className="col-sm-9">: {user?.user?.email}</div>
                                </div>
                                {/* 
                                {
                                    user?.user.ordersId.length > 0 ? <>
                                        <p>Total paid: {user?.ordersInfo.totalPaidByThisUser} ৳</p>
                                        <p>Total due: {user?.ordersInfo.totalDueByThisUser} ৳</p>
                                        <p>Total order value: {user?.ordersInfo.totalOrderValueByThisUser} ৳</p>
                                    </> : <>
                                        <p>Total paid: N/A</p>
                                        <p>Total due: N/A</p>
                                        <p>Total order value: N/A</p>
                                    </>
                                } */}

                            </div>
                            <div className="col-md-4">
                                <div className="row mb-1">
                                    <div className="col-sm-3"><p>Mobile</p></div>
                                    <div className="col-sm-9">: {user?.user?.mobile}</div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-sm-3"><p>Address</p></div>
                                    <div className="col-sm-9">: {user?.user?.address}</div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-sm-3"><p>Balance</p></div>
                                    <div className="col-sm-9">: {user?.user?.accountId?.balance} BDT</div>
                                </div>
                            </div>
                        </div>

                        <hr />

                        <DataTable
                            columns={columns}
                            data={user?.user?.role?.name.toLowerCase() === 'supplier' ? user?.user?.supplierOrders : user?.user?.ordersId}
                            highlightOnHover
                            progressPending={userLoading}
                            pagination
                        />

                    </div>
                </div>
            </div>
            {/* ================================================== */}
        </div>
    );
};

export default ViewUser;