import React from 'react';
import Select from 'react-select'
import { selectStyle } from '../hooks/helpers';

const RSelect = ({ 
    defaultValue,
    options,
    setSelectedOption,
}) => {
    return (
        <>
            <Select
                defaultValue={defaultValue}
                options={options}
                getOptionLabel={(option) => `${option.label}`}
                onChange={(option) => {
                    setSelectedOption(option);
                }}
                className="basic-single"
                classNamePrefix="select"
                styles={selectStyle}
                required
            />
        </>
    );
};

export default RSelect;