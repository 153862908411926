import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { IoTrash } from "react-icons/io5";
import Loading from '../../Shared/Loading/Loading';
import Breadcrumb from '../../../Utilities/Breadcrumb';
import DataTable from 'react-data-table-component';
import toast from 'react-hot-toast';
import useDelete from '../../../hooks/useDelete';
import SearchForm from '../../../Utilities/SearchForm';

const Stocks = () => {

    const navigate = useNavigate();
    const { sendDeleteRequest } = useDelete();
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');

    // get all stocks
    const { data: stocks, isLoading, refetch } = useQuery(['stocks', searchText], () =>
        fetch(`${process.env.REACT_APP_SERVER_URL}stock/index?name=${searchText}`, {
            headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
            .then(res => {
                if (res.status === 401 || res.status === 403) {
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('userId');
                    navigate('/login');
                    toast.error('Forbidden/Unauthorized access!');
                }
                if (res.status === 500) {
                    toast.error('Server error! Please check');
                }
                return res.json();
            }))

    // set the searh to search query
    const handleSearchText = (e) => {
        e.preventDefault();
        setSearchText(e.target.search.value);
    }

    const handleDeleteStock = (stockId) => {
        sendDeleteRequest(`${process.env.REACT_APP_SERVER_URL}stock/${stockId}`, setLoading, refetch);
    }

    // datas
    const columns = [
        {
            name: 'Product Name',
            selector: row => row.productId?.name,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Stock Quantity',
            selector: row => row.stockQuantity + 'PC',
            filterable: true,
            sortable: true,
        },
        {
            name: 'Entry Date',
            selector: row => row.createdAt.slice(0, 10),
            filterable: true,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => (
                <>
                    <button className='btn btn-danger' onClick={() => handleDeleteStock(row._id)}><IoTrash /></button>
                </>
            ),
        },
    ];
    // loading
    if (isLoading || loading) { return <Loading getHeight={'80vh'} /> }

    return (
        <div>

            {/* ================== breadcrumb ==================== */}
            <Breadcrumb
                indexTitle={'Stock Records'}
                indexLink={'/dashboard/stocks'}
            />
            {/* ================================================== */}


            {/* main content */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card py-4 px-3">

                        <DataTable
                            columns={columns}
                            data={stocks.stocks}
                            highlightOnHover
                            progressPending={isLoading}
                            pagination
                            paginationPerPage={15}
                            subHeader
                            subHeaderComponent={
                                <SearchForm
                                    searchText={searchText}
                                    handleSearchText={handleSearchText}
                                    placeholder={'Type product name & hit enter'}
                                />
                            }
                        />

                    </div>
                </div>
            </div>
            {/* main content */}
        </div>
    );
};

export default Stocks;