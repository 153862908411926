import React, { useState } from 'react'
import { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-hot-toast';
import { AiOutlineEye } from 'react-icons/ai';
import { IoTrash } from 'react-icons/io5';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import useDelete from '../../../hooks/useDelete';
import Breadcrumb from '../../../Utilities/Breadcrumb'
import SearchFormLive from '../../../Utilities/SearchFormLive';
import Loading from '../../Shared/Loading/Loading';

const SupplierOrders = () => {

    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const [filterOrders, setFilterOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const { sendDeleteRequest } = useDelete();

    // get all orders
    const { data: orders, isLoading, refetch } = useQuery(['supporders', searchText], () =>
        fetch(`${process.env.REACT_APP_SERVER_URL}supplier-order/views?invoiceId=${searchText}`, {
            headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
            .then(res => {
                if (res.status === 401 || res.status === 403) {
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('userId');
                    navigate('/login');
                    toast.error('Forbidden/Unauthorized access!');
                }
                if (res.status === 500) {
                    toast.error('Forbidden/Unauthorized access!');
                }
                return res.json();
            }))

            console.log(filterOrders)

    useEffect(() => {
        if(searchText){
            setFilterOrders(orders?.orders.filter((el) =>  
                el.invoiceId.toLowerCase()?.match(new RegExp(searchText.toLowerCase(), "i")) || (el?.supplier?.email)?.match(new RegExp(searchText, "i")) || (el?.supplier?.name.toLowerCase())?.match(new RegExp(searchText.toLowerCase(), "i"))
            ))
        }else{
            setFilterOrders(orders?.orders)
        }
    },[searchText, orders?.orders])

    // view single order
    const handleViewOrder = (orderId) => {
        navigate(`/dashboard/supplier/order/${orderId}`)
    }

    // delete return data
    const handleDeleteOrder = (orderId) => {
        sendDeleteRequest(`${process.env.REACT_APP_SERVER_URL}supplier-order/${orderId}`, setLoading, refetch);
    }

    // datas
    const columns = [
        {
            name: 'Invoice No',
            selector: row => row.invoiceId,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Supplier',
            selector: row => <span>{row?.supplier?.name}</span>,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Supplier Email',
            selector: row => <span>{row.supplier?.email}</span>,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Due',
            selector: row => row.due > 0 ? <div className='due-status bg-danger'>{row.due} TK</div> : <div className='paid-status'>Paid</div>,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Date',
            selector: row => row.createdAt.substring(0, 10),
            filterable: true,
            sortable: true,
        },
        // {
        //     name: 'Status',
        //     selector: row => row.status === false ?
        //         <div className='due-status cursor-ponter' onClick={() => handleOrderStatus(row._id, true)}>Processing</div>
        //         : <div className='paid-status'>Delivered</div>,
        //     filterable: true,
        //     sortable: true,
        // },
        {
            name: 'Action',
            cell: row => (
                <>
                    <button className='btn account-btn me-2' onClick={() => handleViewOrder(row._id)}>
                        <AiOutlineEye className='text-white' />
                    </button>
                    <button className='btn btn-danger' onClick={() => handleDeleteOrder(row._id)}><IoTrash /></button>
                </>
            ),
        },
    ];

    // loading
    if (loading) { return <Loading getHeight={'80vh'} /> }

    return (
        <>
            <Breadcrumb
                indexTitle={'Suppliers'}
                indexLink={'/dashboard/suppliers'}
                createPageTitle={'Suppliers Orders'}
                createPageLink={'/dashboard/supplier/orders'}
            />

            {/* main content */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card py-4 px-3">

                    <div className='d-flex justify-content-end'>
                            <SearchFormLive
                                placeholder={`Search by invoice id, name & email`}
                                handleChange={(e) => setSearchText(e.target.value)}
                                selectNeed={false}
                            />
                        </div>

                        <DataTable
                            columns={columns}
                            data={filterOrders}
                            highlightOnHover
                            progressPending={isLoading}
                            pagination
                            paginationPerPage={15}
                            persistTableHead={true}
                        />

                    </div>
                </div>
            </div>
            {/* main content */}
        </>
    )
}

export default SupplierOrders