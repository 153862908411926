import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../Shared/Loading/Loading';
import toast from 'react-hot-toast';
import axios from 'axios'
import useCategories from '../../../hooks/useCategories';
import Breadcrumb from '../../../Utilities/Breadcrumb';
import Input from '../../../Utilities/Input';
import Select from '../../../Utilities/Select';

const EditProduct = () => {

    let { productId } = useParams();
    const navigate = useNavigate();
    const [imgSrc, setImgSrc] = useState('');
    const [loading, setLoading] = useState(false);

    const [productData, setProductData] = useState({
        name: '', slug: '', img: '', price: 0, quantity: 0, sold: 0, description: '', categoryId: '', featured: false, status: 'active'
    });
    const [errors, setError] = useState({
        name: '', status: '', img: ''
    });

    // get all categories
    const { categories, categoriesLoading } = useCategories('');

    // getting specific user by id
    const { data: product, isLoading } = useQuery(['singleProduct', productId], () =>
        fetch(`${process.env.REACT_APP_SERVER_URL}product/${productId}`, {
            headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
            .then(res => {
                if (res.status === 401 || res.status === 401) {
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('userId');
                    navigate('/login');
                    toast.error('Forbidden/Unauthorized access!');
                }
                return res.json();
            }))

    // getting data and set in state
    useEffect(() => {
        if (product?.success === true) {
            setProductData({
                name: product?.product.name, slug: product?.product.slug, img: product?.product.img, price: product?.product.price, quantity: product?.product.quantity, description: product?.product.description, categoryId: product?.product.categoryId, featured: false, status: product?.product.status
            });
        }
    }, [product]);

    // image validation
    const handleImageChange = e => {
        if (!e.target.files[0].name.match(/\.(jpg|jpeg|png|JPG|PNG)$/)) {
            setImgSrc('');
            setProductData({ ...productData, img: product?.product.img })
            setError({ ...errors, img: 'Only jpg, jpeg & png file supported' });
        } else {
            setError({ ...errors, img: '' });
            setImgSrc(URL.createObjectURL(e.target.files[0]));
            setProductData({ ...productData, img: e.target.files[0] })
        }
    }

    // edit product
    const handleEditProduct = (e) => {
        e.preventDefault();
        setLoading(true);

        var formData = new FormData();
        formData.append('name', productData.name);
        formData.append('img', productData.img);
        formData.append('categoryId', productData.categoryId);
        formData.append('price', productData.price);
        formData.append('quantity', productData.quantity);
        formData.append('description', productData.description);
        formData.append('status', productData.status);


        const config = {
            headers: {
                authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
        };

        axios.patch(`${process.env.REACT_APP_SERVER_URL}product/${productId}`, formData, config)
            .then(res => {
                setLoading(false);

                if (res.status === 401 || res.status === 403) {
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('userId');
                    navigate('/login');
                    toast.error('Forbidden/Unauthorized access!');
                }

                if (res.status === 200 && res.data.success === true) {
                    toast.success(res.data.message);
                }

                if (res.data.success === false) {
                    toast.success(res.data.message);
                }
            })
    }

    if (isLoading || categoriesLoading) { return <Loading /> }

    return (
        <form encType='multipart/form-data' onSubmit={handleEditProduct}>

            {/* ================== breadcrumb ==================== */}
            <Breadcrumb
                indexTitle={'Products'}
                indexLink={'/dashboard/products'}
                editPageTitle={`Edit [${product?.product.name}]`}
                editPageLink={`/dashboard/edit-product/${productId}`}
                btnTitle={'Update Product'}
                loading={loading}
            />
            {/* ================================================== */}


            {/* main content */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card py-4 px-3">

                        <h4>Edit Product</h4>
                        <hr />

                        <div className="row gx-5 gy-4">

                            <div className="col-sm-12">
                                <div className='row justify-content-center'>
                                    <div className="col-md-6 mt-2" >
                                        {
                                            (imgSrc || product?.product?.img) && <div className='text-center add-img mb-2'>
                                                <img width={100} height={100}
                                                    src={imgSrc ? imgSrc : `${process.env.REACT_APP_SERVER_URL}${product?.product?.img}`}
                                                    alt={product?.product?.name}
                                                    className="my-3" />
                                            </div>
                                        }

                                        <div class="box text-center">
                                            <input type="file" name="img" id="img" class="inputfile inputfile-1" onChange={handleImageChange} />
                                            <label for="img"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17"><path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" /></svg> <span>Choose a file&hellip;</span></label>
                                        </div>
                                        {
                                            errors.img && <p className='text-danger m-0'> {errors.img} </p>
                                        }
                                    </div>
                                </div>
                            </div>

                            <Input
                                label={'Product name'} type={'text'} inputId={'name'}
                                placeholder={'Enter product name'} isRequired={true}
                                inputValue={productData?.name} errorValue={errors.price}
                                onChangeData={(e) => setProductData({
                                    ...productData, name: e.target.value,
                                    slug: e.target.value.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '')
                                })}
                            />

                            <Input
                                label={'Product Slug'} type={'text'} inputId={'slug'}
                                placeholder={'Enter product slug'} isRequired={true} isDisabled={true}
                                inputValue={productData?.slug} errorValue={errors.slug}
                            />

                            <Select
                                label={'Product Category'} isRequired={true} inputId={'categoryId'}
                                inputValue={productData.categoryId} errorValue={errors.categoryId}
                                onChangeData={(e) => setProductData({ ...productData, categoryId: e.target.value })}
                                options={categories?.categories}
                            />

                            <Input
                                label={'Product Price'} type={'number'} inputId={'price'}
                                placeholder={'Enter product price'} isRequired={true}
                                inputValue={productData?.price} errorValue={errors.price}
                                onChangeData={(e) => setProductData({ ...productData, price: e.target.value && parseFloat(e.target.value) })}
                            />

                            <Input
                                label={'Product Quantity'} type={'text'} inputId={'quantity'}
                                placeholder={'Enter product quantity'} isRequired={true}
                                inputValue={productData?.quantity} errorValue={errors.quantity}
                                onChangeData={(e) => setProductData({ ...productData, quantity: e.target.value && parseInt(e.target.value) })}
                            />

                            <Select
                                label={'Product Status'} isRequired={true} inputId={'status'}
                                inputValue={productData.status} errorValue={errors.status}
                                onChangeData={(e) => setProductData({ ...productData, status: e.target.value })}
                                options={[
                                    { 'name': 'Active', 'value': 'active' },
                                    { 'name': 'Inactive', 'value': 'inactive' }
                                ]}
                            />

                            <Input
                                isTextarea={true} colSize={'12'}
                                label={'Product Description'} type={'text'} inputId={'description'}
                                placeholder={'Enter product description'} isRequired={false}
                                inputValue={productData?.description}
                                onChangeData={(e) => setProductData({ ...productData, description: e.target.value })}
                            />

                        </div>
                    </div>
                </div>
            </div>
            {/* main content */}
        </form>
    );
};

export default EditProduct;