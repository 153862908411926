import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const SuppAddCash = ({ showModal, handleModalClose, userName, userId, setLoading, refetch }) => {

    const navigate= useNavigate();
    const [data, setData] = useState({ cash: 0, userId: userId });

    const handleAddCash = () => {
        if(data.cash <= 0){
            toast.error('Cash value is required!');
        }else{
            setLoading(true);
            fetch(`${process.env.REACT_APP_SERVER_URL}supplier/account/add-cash`, {
                method: 'POST',
                headers: {
                    'content-Type': 'application/json',
                    authorization: `Bearer ${localStorage.getItem('accessToken')}`
                },
                body: JSON.stringify(data)
            })
                .then(res => {
                    if (res.status === 401 || res.status === 403) {
                        localStorage.removeItem('accessToken');
                        localStorage.removeItem('userId');
                        navigate('/login');
                        toast.error('Forbidden/Unauthorized access!');
                    }
                    return res.json();
                })
                .then(data => {
                    setLoading(false);
                    if (data.message && data.success === true) {
                        refetch();
                        handleModalClose();
                        setData({ cash: 0 });
                        toast.success(data.message);
                    }
                    if (data.message && data.success === false) {
                        toast.error(data.message);
                    }
                })
        }
    }

    return (
        <Modal show={showModal} onHide={handleModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{userName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-sm-12">
                        <label htmlFor="cash" className="form-label">Amount to cash<span className='text-danger'>*</span></label>
                        <input type="number" className="form-control" id="cash" placeholder="Amount of cash to add"
                            value={data?.cash}
                            required
                            onChange={(e) => setData({ ...data, cash: e.target.value && parseFloat(e.target.value) })}
                        />

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => handleAddCash()}>
                        Add Cash
                    </Button>
                </Modal.Footer>
            </Modal>
    );
};

export default SuppAddCash;