import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
// import Loading from '../Loading/Loading'

const RequiredAuth = ({ children }) => {
      // const userId = localStorage.getItem('userId');
      const { logged } = useAuth();
      const userId = localStorage.getItem('userId');

      // if(loggedUserLoading){ return <Loading /> }

      if(!userId || !logged) { return <Navigate to="/login" />   }
      
      return children;
};

export default RequiredAuth;