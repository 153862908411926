import React from 'react';
import { IoArrowBackSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import useAuth from '../../../../hooks/useAuth';
import Menu from './Menu';
import userDemo from '../../../../assets/images/user-demo.png'

const DashboardSidebar = ({ toggleClassState, handleToggleCollapse }) => {

    const { loggedUser } = useAuth();

    return (
        <div className={!toggleClassState ? "dashboard-sidebar" : "dashboard-sidebar dashboard-sidebar-close"}>
            <div className="sidebar-sticky">

                <div className="sidebar-avater py-3">
                    <Link to={'/dashboard'}>
                        <div className="d-flex align-items-center border rounded">
                            <div className="menu_icon">
                                <img className='user-avater' width={90}
                                    src={loggedUser?.name ? `https://ui-avatars.com/api/?name=${loggedUser?.name.split(" ").join("+")}` : `${userDemo}`}
                                    // src={loggedUser?.img ? `${process.env.REACT_APP_SERVER_URL}${loggedUser.img}` : `${userDemo}`}
                                    alt={loggedUser?.img ? loggedUser.name : 'Tamim'} />
                            </div>
                            <div className="menu_name px-2">
                                {loggedUser?.name ? loggedUser.name : 'Tamim'}
                                <div className="role"><small>{loggedUser?.role?.name ? loggedUser?.role?.name : 'No Role'}</small> </div>
                            </div>
                        </div>
                    </Link>

                </div>

                <ul className="nav flex-column">
                    <Menu></Menu>
                </ul>

                <div className="colapse-btn" onClick={handleToggleCollapse}>
                    <div className="menu_icon  text-white text-center  csbg_primary"> <IoArrowBackSharp /></div>
                </div>

            </div>
        </div>
    );
};

export default DashboardSidebar;