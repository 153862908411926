import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import toast from "react-hot-toast";

const useUsers = (searchText) => {
    const navigate = useNavigate();

    const { data: users, isLoading, refetch } = useQuery(['allUsers', searchText], () =>
    fetch(`${process.env.REACT_APP_SERVER_URL}user/index?name=${searchText}`, {
        headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
    })
        .then(res => {
            if (res.status === 401 || res.status === 403) {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('userId');
                navigate('/login');
                toast.error('Forbidden/Unauthorized access!', { duration: 200 });
            }
            if (res.status === 500) {
                toast.error('Forbidden/Unauthorized access!', { duration: 2000 });
            }
            return res.json();
        }))

    return { users, usersLoading: isLoading, usersFetch: refetch };
}

export default useUsers;