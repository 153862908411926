import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import toast from "react-hot-toast";

const useRoles = () => {
    const navigate = useNavigate();

    const { data: roles, isLoading, refetch } = useQuery(['mainRoles'], () =>
    fetch(`${process.env.REACT_APP_SERVER_URL}role/index`, {
        headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
    })
    .then(res => {
        if (res.status === 401 || res.status === 403) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('userId');
            navigate('/login');
            toast.error('Forbidden/Unauthorized access!', { duration: 2000 });
        }
        if (res.status === 500) {
            toast.error('Server error! Please check!', { duration: 2000 });
        }
        return res.json();
}))

    return { roles, rolesLoading: isLoading, rolesFetch: refetch };
}

export default useRoles;